<template>
  <div>
    <ul v-if="tabs.length > 1" class="tab-nav" :style="tabBarStyle">
      <li v-for="tab of tabs" :key="tab.value" :style="navItensStyle" :class="{'nav-item-active': value === tab.value }"
        @click="() => $emit('input', tab.value)">
        {{tab.title}}
      </li>
    </ul>
    <div>
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array,
      required: true,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    navItensStyle: {
      type: Object,
    },
    tabBarStyle: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.tab-nav {
  list-style: none;
  padding-inline-start: 0;
  width: 100%;
  display: flex;

}

.tab-nav li {
  color: #7e829f;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-right: 14px;
  cursor: pointer;
}

.nav-item-active {
  border-bottom: 3px solid #209f85 !important;
  color: #209f85 !important;
  margin-bottom: -2.5px;
}
</style>
