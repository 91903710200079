<template>
  <b-row :style="margins" id="margins">
    <b-col md="12">
      <div id="separator" :style="separator" />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Separator',
  props: {
    bgColor: String,
    height: String,
    marginTop: String,
    marginBottom: String,
  },
  computed: {
    separator() {
      return {
        '--bgColor': this.bgColor || '#D4D4D4',
        '--height': `${this.height || '1'}px`,
      };
    },
    margins() {
      return {
        '--margin-top': `${this.marginTop || 30}px`,
        '--margin-bottom': `${this.marginBottom || 30}px`,
      };
    },
  },
};
</script>

<style scoped>
  #separator {
    background-color: var(--bgColor);
    height: var(--height);
  }
  #margins {
    margin-top: var(--margin-top);
    margin-bottom: var(--margin-bottom);
  }
</style>
