<template>
  <div>
    <b-row>
      <b-col sm="6" md="6" lg="6" xl="6">
        <b-form-group label="Tipo de processamento">
          <b-form-radio-group
            v-model="lavagemAutomatizada"
            @input="onRadioChange($event)"
            id="lavagemAutomatizada"
            name="lavagemAutomatizada"
          >
            <b-form-radio :value="1">Lavagem Automatizada</b-form-radio>
            <b-form-radio :value="0">Desinfecção</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col v-if="!lavagemAutomatizada">
        <b-form-group
          label="Tipo de desinfecção*"
          label-for="tipo_esterilizacao"
        >
          <GenericSelect
            ref="tipoEsterilizacao"
            name="tipo_esterilizacao"
            labelKey="nome"
            :value="model.id_tipo_esterilizacao"
            :disabled="lavagemAutomatizada === null"
            :route="tipoEsterilizacaoRoute"
            :customFilters="chavesFilter"
            @input="v => handleChangeTipoEsterilizacao(v)"
            v-validate="{required: !lavagemAutomatizada}"
            :state="validateState('tipo_esterilizacao')"
          ></GenericSelect>
        </b-form-group>
      </b-col>
      <b-col v-else>
        <b-form-group
          label="Equipamento de lavagem automatizada*"
          label-for="equipamento_lavagem_automatizada"
        >
          <GenericSelect
            name="equipamento_lavagem_automatizada"
            id="equipamento_lavagem_automatizada"
            ref="equipamento_lavagem_automatizada"
            labelKey="nome"
            :value="model.id_equipamento_lavagem_automatizada"
            :customFilters="equipamentoLavagemAutomatizadaFilters"
            route="equipamento"
            :state="validateState('equipamento_lavagem_automatizada')"
            v-validate="{ required: lavagemAutomatizada }"
            @input="updateEquipamentoAndLote"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Lote do equipamento*"
          label-for="lote"
          v-if="!lavagemAutomatizada && tipoDesinfeccaoChave === 'termodesinfeccao'"
        >
          <b-form-input
            name="lote"
            :value="model.lote"
            autocomplete="off"
            class="invision-input"
            v-validate="{ required: true }"
            :state="validateState('lote')"
            @input="v => handleChangeField('lote', v)"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Lote do detergente enzimático*"
          label-for="lote_detergente"
          v-if="lavagemAutomatizada"
        >
          <b-form-input
            name="lote_detergente"
            :value="model.lote_detergente"
            autocomplete="off"
            class="invision-input"
            v-validate="{ required: !lavagemAutomatizada}"
            :state="validateState('lote_detergente')"
            :disabled="!!model.id_equipamento_lavagem_automatizada"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Tipo de embalagem*"
          label-for="tipo_embalagem"
        >
          <GenericSelect
            name="tipoEmbalagem"
            labelKey="nome"
            :value="model.id_tipo_embalagem"
            :route="tipoEmbalagemRoute"
            @input="v => handleChangeField('id_tipo_embalagem', v)"
          ></GenericSelect>
        </b-form-group>
      </b-col>
      <b-col v-if="!lavagemAutomatizada">
        <b-form-group label="Validade*" label-for="validade">
          <b-form-datepicker
            id="validade"
            class="invision-input"
            placeholder="Data de validade"
            :value="model.validade"
            @input="v => handleChangeField('validade', v)"
            name="validade"
            locale="pt"
            :disabled="true"
            v-validate="{ required: true }"
            :state="validateState('validade')"
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-form-group
          label="Nome do Colaborador*"
          label-for="colaborador_lavagem_automatizada"
        >
          <br />
          <b-form-input
            id="colaborador_lavagem_automatizada"
            :value="model.colaborador_lavagem_automatizada"
            autocomplete="off"
            class="invision-input"
            name="colaborador_lavagem_automatizada"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('colaborador_lavagem_automatizada')"
            @input="v => handleChangeField('colaborador_lavagem_automatizada', v)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';
import GenericApi from '../../../../../front-end/src/services/genericRequest';

export default {
  name: 'InformacoesPreparoInox',
  inject: ['parentValidator', 'validateState', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },

  components: {
    GenericSelect,
  },

  computed: {
    ...mapState({
      currentUnidade: (state) => state.currentUnidade,
    }),
    equipamentoLavagemAutomatizadaFilters() {
      return {
        id_unidade: this.currentUnidade.id_unidade,
        lavagem_automatizada: true,
      };
    },
  },

  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tipoEmbalagemRoute: 'tipoEmbalagem',
      equipamentoRoute: 'equipamento',
      tipoEsterilizacaoRoute: 'tipoEsterilizacao',
      chavesFilter: { chave: ['termodesinfeccao', 'desinfeccaoAlcool'] },
      isDisabled: true,
      validadeParams: {
        id_tipo_esterilizacao: null,
        id_tipo_embalagem: null,
      },
      tipoDesinfeccaoChave: '',
      lavagemAutomatizada: 0,
      id_equipamento_lavagem_automatizada: null,
      equipamentosLavagemAutomatizada: []
    };
  },

  async mounted() {
    await this.getIdTipoEmbalagemDefaultValue();
    await this.getEquipamentoAndTipoEsterilizacao('termodesinfeccao');
    await this.getLavagemAutomatizadaDefaultValue(this.equipamentoLavagemAutomatizadaFilters)
  },

  watch: {
    validadeParams: {
      deep: true,
      async handler(val) {
        if (val.id_tipo_esterilizacao && val.id_tipo_embalagem) {
          const validParams = Object.keys(val).reduce((Acum, curr) => {
            if (val[curr]) return { ...Acum, [curr]: val[curr] };
            return Acum;
          }, {});
          const validade = await GenericApi.getWithoutPagination(
            {
              customFilters: validParams,
            },
            'validadeEsterilizacao/validadeInox',
          );

          if (validade && validade[0]) {
            this.updateModel('validade', moment()
              .add(validade[0].dias, 'days')
              .format('YYYY-MM-DD'));
          } else {
            this.updateModel('validade', '');
          }
        }
      },
    },
  },

  methods: {
    onRadioChange(lavagem) {
      if (lavagem === 1) {
        // Setar id_tipo_embalagem para SMS
        this.handleChangeField('id_tipo_embalagem', 1)
        // Não setar id_tipo_esetilizacao para não calcular validade
        this.handleChangeField('id_tipo_esterilizacao', null)
        // Se já tiver calculado uma validade, zerar
        this.handleChangeField('validade', null)
        this.updateEquipamentoAndLote(19)
        this.handleChangeField('lote','');
      }
      else if(lavagem === 0) {
        this.handleChangeField('id_tipo_embalagem', 5)
        this.handleChangeField('lote_detergente', '')
      }
      this.$validator.validateAll()
    },
    updateEquipamentoAndLote(id_equipamento) {
      this.id_equipamento_lavagem_automatizada = id_equipamento;
      if (!id_equipamento) {
        return;
      }
      const equipamento = this.$refs.equipamento_lavagem_automatizada && this.$refs.equipamento_lavagem_automatizada.apiResponse.find(
        (item) => item.id_equipamento === id_equipamento,
      ) || this.equipamentosLavagemAutomatizada.find(equipamento => equipamento.id_equipamento === id_equipamento);
      let loteDetergente = '';
      if (equipamento) {
        const { lote_detergente } = equipamento.loteDetergente;
        loteDetergente = lote_detergente;
      }
      this.handleChangeField(
        'id_equipamento_lavagem_automatizada',
        this.id_equipamento_lavagem_automatizada,
      );
      this.handleChangeField(
        'lote_detergente',
        loteDetergente,
      );
    },
    handleChangeField(prop, value) {
      this.validadeParams[prop] = value;
      this.updateModel(prop, value);
    },

    async handleChangeTipoEsterilizacao(v) {
      this.validadeParams.id_tipo_esterilizacao = v;
      this.handleChangeField('id_tipo_esterilizacao', v);
      this.tipoDesinfeccaoChave = this.$refs.tipoEsterilizacao.getChaveById(v, 'id_tipo_esterilizacao');
      await this.getEquipamentoAndTipoEsterilizacao(this.tipoDesinfeccaoChave);
    },

    async getEquipamentoAndTipoEsterilizacao(tipoDesinfeccao) {
      if (tipoDesinfeccao === 'desinfeccaoAlcool') {
        this.handleChangeField('id_equipamento', null);
        this.handleChangeField('lote', '');
      } else {
        const result = await GenericApi.getAllWithoutPagination({ chave: 'inox' },
          'equipamento/chave/tipoMaterial');
        if (!result.length) {
          await this.getTipoDesinfeccaoDefaultValue('desinfeccaoAlcool');
        } else {
          await this.getTipoDesinfeccaoDefaultValue('termodesinfeccao');
          // this.updateModel('lote_detergente', result[0].loteDetergente.lote_detergente);
          this.handleChangeField('id_equipamento', result[0].id_equipamento);
          if (result.length > 1) this.isDisabled = false;
        }
      }
    },

    async getIdTipoEmbalagemDefaultValue() {
      const result = await GenericApi.getAllWithoutPagination(
        { id_tipo_material: 7, id_campo_material: 16 },
        'tipoMaterialCampoMaterial/tipoMaterialCampoMaterialByParams',
      );
      this.handleChangeField('id_tipo_embalagem', +result[0].padrao);
      this.validadeParams.id_tipo_embalagem = +result[0].padrao;
    },

    async getTipoDesinfeccaoDefaultValue(chave) {
      const result = await GenericApi.getAllWithoutPagination(
        { chave },
        'tipoEsterilizacao',
      );
      this.tipoDesinfeccaoChave = result[0].chave;
      this.validadeParams.id_tipo_esterilizacao = result[0].id_tipo_esterilizacao;
      this.handleChangeField('id_tipo_esterilizacao', result[0].id_tipo_esterilizacao);
    },
    async getLavagemAutomatizadaDefaultValue(filters) {
      const result = await GenericApi.getAllWithoutPagination({...filters}, 'equipamento')
      this.equipamentosLavagemAutomatizada = result  
    }
  },
};
</script>
