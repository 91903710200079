<template>
  <div class="mb-4">
    <b-row>
      <b-col sm="6" md="6" lg="6" xl="6">
        <b-form-group label="Tipo de processamento">
          <b-form-radio-group
            v-model="idTipoLavagemAutomatizada"
            @input="updateTipoLavagemAutomatizada"
            id="tipoLavagemAutomatizada"
            name="tipoLavagemAutomatizada"
          >
            <b-form-radio :value="2">Lavagem Automatizada</b-form-radio>
            <b-form-radio :value="1">Termodesinfecção</b-form-radio>
            <b-form-radio :value="0">Limpeza Manual</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      class="sub-form"
      v-if="dataModel.lavagem_automatizada"
    >
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group
          label="Nome do Colaborador*"
          label-for="colaborador_lavagem_automatizada"
        >
          <br />
          <b-form-input
            id="colaborador_lavagem_automatizada"
            :value="dataModel.colaborador_lavagem_automatizada"
            autocomplete="off"
            class="invision-input"
            name="colaborador_lavagem_automatizada"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('colaborador_lavagem_automatizada')"
            @input="v => handleChangeField('colaborador_lavagem_automatizada', v)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group
          label="Lote do detergente enzimático*"
          label-for="lote_detergente_lavagem_automatizada"
        >
          <b-form-input
            id="lote_detergente_lavagem_automatizada"
            :value="dataModel.lote_detergente_lavagem_automatizada"
            autocomplete="off"
            class="invision-input"
            name="lote_detergente_lavagem_automatizada"
            disabled
            placeholder="Selecione o equipamento"
            v-validate="{ required: true }"
            :state="validateState('lote_detergente_lavagem_automatizada')"
            @input="v => handleChangeField('lote_detergente_lavagem_automatizada', v)"
          />
        </b-form-group>
      </b-col>
      <b-col :cols="dataModel.id_tipo_lavagem_automatizada === 1 ? 6 : 12">
        <b-form-group
          :label="dataModel.id_tipo_lavagem_automatizada === 1
            ? 'Equipamento termodesinfecção*'
            : 'Equipamento de lavagem automatizada*'
          "
          label-for="equipamento_lavagem_automatizada"
        >
          <GenericSelect
            name="equipamento_lavagem_automatizada"
            id="equipamento_lavagem_automatizada"
            ref="equipamento_lavagem_automatizada"
            labelKey="nome"
            :value="dataModel.id_equipamento_lavagem_automatizada"
            :customFilters="equipamentoLavagemAutomatizadaFilters"
            route="equipamento"
            :disabled="isBusy"
            :state="validateState('equipamento_lavagem_automatizada')"
            v-validate="{ required: true }"
            @input="updateEquipamentoAndLote"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6" v-if="dataModel.id_tipo_lavagem_automatizada === 1">
        <b-form-group
          label="Lote do equipamento*"
        >
          <br />
          <b-form-input
            id="lote_equipamento_termodesinfeccao"
            :value="dataModel.lote"
            autocomplete="off"
            class="invision-input"
            name="lote"
            v-validate="{ required: true }"
            :state="validateState('lote')"
            @input="v => handleChangeField('lote', v)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="dataModel.lavagem_automatizada === false">
      <b-col>
        <b-form-checkbox
          :checked="dataModel.naoFazerAutomatizada"
          @input="v => handleChangeField('naoFazerAutomatizada', v)"
          :state="dataModel.naoFazerAutomatizada"
          v-validate="'hasToBeTrue'"
          name="naoFazerAutomatizada"
        >
          Desejo finalizar o processo sem registro de limpeza automatizada ou termodesinfecção
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';

export default {
  name: 'TermoLavagemAuto',
  components: {
    GenericSelect,
  },
  inject: ['parentValidator', 'validateState', 'updateCurrentMaterialModel', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  data() {
    return {
      idTipoLavagemAutomatizada: null,
    };
  },
  props: {
    currentMaterialModel: {
      type: Object,
    },
    model: {
      type: Object,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(['currentUnidade']),
    equipamentoLavagemAutomatizadaFilters() {
      return {
        id_unidade: this.currentUnidade.id_unidade,
        lavagem_automatizada: true,
        id_tipo_lavagem_automatizada: this.dataModel.id_tipo_lavagem_automatizada,
      };
    },
    saveDataInModel() {
      if (this.model) return true;
      return false;
    },
    dataModel() {
      if (this.saveDataInModel) {
        return this.model;
      }
      return this.currentMaterialModel;
    },
  },
  mounted() {
    this.idTipoLavagemAutomatizada = this.dataModel.id_tipo_lavagem_automatizada;
  },
  methods: {
    handleChangeField(field, value) {
      if (this.saveDataInModel) {
        this.updateModel(field, value);
      } else {
        this.updateCurrentMaterialModel(field, value);
      }
    },
    updateTipoLavagemAutomatizada(v) {
      if (v !== this.dataModel.id_tipo_lavagem_automatizada) {
        this.handleChangeField(
          ['id_tipo_lavagem_automatizada', 'lavagem_automatizada', 'naoFazerAutomatizada', 'lote'],
          [v, Boolean(v), false, ''],
        )
      }
    },
    updateEquipamentoAndLote(id_equipamento) {
      if (!id_equipamento) {
        this.handleChangeField(
          ['id_equipamento_lavagem_automatizada', 'lote_detergente_lavagem_automatizada', 'nomeEquipamento'],
          [id_equipamento, '', ''],
        );
        return;
      }
      const equipamento = this.$refs.equipamento_lavagem_automatizada.apiResponse.find(
        (item) => item.id_equipamento === id_equipamento,
      );
      let loteDetergente = '';
      if (equipamento) {
        const { lote_detergente } = equipamento.loteDetergente;
        loteDetergente = lote_detergente;
      }
      this.handleChangeField(
        ['id_equipamento_lavagem_automatizada', 'lote_detergente_lavagem_automatizada', 'nomeEquipamento'],
        [id_equipamento, loteDetergente, equipamento.nome],
      );
    },
  },
};
</script>
<style>
#tipoLavagemAutomatizada {
  display: flex;
  flex-direction: row;
}
#tipoLavagemAutomatizada .custom-control-label{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
</style>
