<template>
  <div v-if="currentPage === 2">
    <InformacoesSaidaPaciente
      :model="model"
      :isBusy="isBusy"
    />
    <Separator />
    <LeituraMateriais
      :model="model"
      :isBusy="isBusy"
      withoutTitle
      detalheMaterial
    >
      <template #modal>
        <ModalProcesso
          title="Seleção de embalagem"
          preserveData
          :currentMaterialModel="currentMaterialModel"
          :config="config"
          :validationFields="['lote_esterilizacao']"
        >
          <template #processo>
            <b-container>
              <b-row>
                <b-col>
                  <b-form-group label="Lote Equipamento Esterilização/Desinfecção*">
                    <b-form-input
                      id="lote_esterilizacao"
                      name="lote_esterilizacao"
                      :value="currentMaterialModel.lote_esterilizacao"
                      class="invision-input"
                      :disabled="isBusy"
                      v-validate="{ required: true }"
                      :state="validateState('lote_esterilizacao')"
                      @input="v => updateCurrentMaterialModel('lote_esterilizacao', v)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </ModalProcesso>
      </template>
    </LeituraMateriais>
  </div>
</template>

<script>
import config from './config';
import InformacoesSaidaPaciente from './InformacoesSaidaPaciente';
import Separator from '../../../../../../front-end/src/components/Utils/Separator';
import LeituraMateriais from '../../Components/LeituraMateriais';
import ModalProcesso from '../../Components/ModalProcesso';


export default {
  name: 'SaidaPaciente',
  inject: ['parentValidator', 'validateState', 'updateCurrentMaterialModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    InformacoesSaidaPaciente,
    Separator,
    LeituraMateriais,
    ModalProcesso,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      config,
    };
  },
  methods: {
    customChooseMaterialGenerico(currentMaterial) {
      currentMaterial.needModalProcesso = true;
    },
  }
};
</script>
