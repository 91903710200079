export default {
  enableMaterialRede: true,
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [3],
  tabelaMateriaisPage: 4,
  modelProcesso: {
    id_unidade_destino: null,
    id_setor_destino: null,
  },
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Leitura do material',
      subItems: [{ title: 'Validar QR Code' }],
    },
  ],
};
