<template>
  <div>
    <div v-if="currentPage === 2">
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Embalagem*" label-for="embalagem">
            <b-form-select
              :value="model.id_tipo_embalagem"
              :options="options_tipo_embalagem"
              disabled
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Setor*" label-for="setor">
            <b-form-select
              :value="model.id_setor"
              :options="options_setor"
              :disabled="true"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <LeituraMateriais
        :model="model"
        :isBusy="isBusy"
        hideIdDetection
        :disableInitFocus="false"
      />
    </div>
  </div>
</template>

<script>
import LeituraMateriais from '../../Components/LeituraMateriais';
import dataConfig from './config';

const { idSetorSpr, idTipoMaterialGenericoEnxoval, idTipoEmbalagemSMS } = dataConfig;

export default {
  name: 'CadastroPreparoSPR',
  inject: ['parentValidator', 'validateState', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    LeituraMateriais,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
    },
  },
  data() {
    return {
      quantidade_caixas: null,
      descricao_caixas: null,
      quantidade_itens: null,
      options_setor: [
        { value: idSetorSpr, text: 'SPR' },
      ],
      options_tipo_material: [
        { value: idTipoMaterialGenericoEnxoval, text: 'Enxoval' },
      ],
      options_tipo_embalagem: [
        { value: idTipoEmbalagemSMS, text: 'SMS' },
      ],
      camposMaterial: {},
    };
  },
};
</script>
