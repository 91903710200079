<template>
  <div id="table-materiais">
    <div
      v-for="(material, indexMaterial) in model.materiais"
      :key="material.shownId"
      class="each-material"
    >
      <div class="p-3 item-header">
        <b-row>
          <b-col class="header-left" md="10">
            <span class="text-header">{{formatMaterialHeader(material)}}</span>
          </b-col>
          <b-col class="header-right" md="2">
            <b-row>
              <b-col>
                <div class="box-icon box-icon-click mat-hover-visibility" @click="removeMaterialFromList(indexMaterial)">
                  <trash-2-icon class="icons-color" />
                </div>
              </b-col>
              <b-col>
                <div v-if="detalheMaterial" class="box-icon box-icon-click" @click="toggleShowDetalheMaterial(indexMaterial)">
                  <chevron-down-icon class="icons-color" />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </div>
      <div
        v-if="detalheMaterial && showDetalhes[indexMaterial]"
        class="p-3 material-detalhe"
        @mouseleave="mouseLeave"
      >
        <b-row class="wrapper-detalhe">
          <b-col>
            <div class="p-3 inner-item-table">
              <b-row class="pb-3 inner-table-header">
                <b-col md="10">
                  <b-row class="material-header">
                    <b-col>
                      <span>Tipo de Artigo</span>
                    </b-col>
                    <b-col>
                      <span>Peças</span>
                    </b-col>
                    <b-col v-if="material.qt_material_generico">
                      <span>Qtd</span>
                    </b-col>
                    <b-col v-if="showPertenceA">
                      <span>Pertence a</span>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="2">
                </b-col>
              </b-row>
              <b-row class="pt-3">
                <b-col md="10">
                  <b-row>
                    <b-col>
                      <span class="inner-table-text">{{ getTipoArtigo(material) }}</span>
                    </b-col>
                    <b-col>
                      <span class="inner-table-text">{{ getPecas(material) }}</span>
                    </b-col>
                    <b-col v-if="material.qt_material_generico">
                      {{ material.qt_material_generico }}
                    </b-col>
                    <b-col v-if="showPertenceA">
                      {{ material.pertenceA }}
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="2">
                  <b-row>
                    <b-col>
                      <div
                        v-if="hasIntegridade"
                        class="box-icon box-icon-click mat-hover-visibility"
                        @click="handleIntegridade(material)"
                      >
                        <alert-triangle-icon
                          class="icons-color"
                          :class="material.naoConformidade && 'nao-conformidade'"
                        />
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        v-if="hasOptions && material.id_material && enableReimprimirEtiqueta"
                        class="box-icon box-icon-click mat-hover-visibility"
                        @click="(indexMaterial) => {}"
                      >
                        <more-horizontal-icon
                          class="h5 icons-color"
                          @click="handleOpenOptionsMenu(material.readId)"
                        />
                        <div id="item-menu" v-if="openMenuFor === material.readId">
                          <span
                            v-if="material.id_material && enableReimprimirEtiqueta"
                            class="item-menu-option"
                            @click="handleReimprimirEtiqueta(material)"
                          >
                            Reimprimir etiqueta do material
                          </span>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabelaLeituraMateriais',
  inject: [
    'removeMaterial',
    'updateShowDetalhes',
    'handleReimprimirEtiqueta',
    'handleIntegridade'
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    showPertenceA: {
      type: Boolean,
      default: false
    },
    detalheMaterial: {
      type: Boolean,
      default: false
    },
    showDetalhes: {
      type: Object,
      required: true
    },
    hasIntegridade: {
      type: Boolean,
      default: false,
      required: false
    },
    hasOptions: {
      type: Boolean,
      default: false,
    },
    enableReimprimirEtiqueta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openMenuFor: null,
    };
  },
  methods: {
    formatMaterialHeader(material) {
      if (material.shownId) return `${material.descricao} - ID: ${material.shownId}`;
      if (!this.detalheMaterial && material.qt_material_generico) {
        return `${material.label} - Qtd: ${material.qt_material_generico}`;
      }
      return material.label;
    },
    getTipoArtigo(material) {
      if (material.tipoMaterial) return material.tipoMaterial.nome;
      if (material.tipoMaterialGenerico) return material.tipoMaterialGenerico.nome;
      return '';
    },
    getPecas(material) {
      return material.quantidade_atual_itens || material.quantidade_itens || material.pecas || '';
    },
    toggleShowDetalheMaterial(indexMaterial) {
      this.updateShowDetalhes(
        {
          ...this.showDetalhes,
          [indexMaterial]: !Boolean(this.showDetalhes[indexMaterial])
        }
      )
    },
    async removeMaterialFromList(indexMaterial) {
      const removed = await this.removeMaterial(indexMaterial);
      if (removed) {
        this.updateShowDetalhes({})
      }
    },
    handleOpenOptionsMenu(readId) {
      if (this.openMenuFor !== readId) {
        this.openMenuFor = readId;
        return;
      }
      this.openMenuFor = null;
    },
    mouseLeave() {
      this.openMenuFor = null;
    },
  }
}
</script>

<style scoped>
.nao-conformidade {
  color:#209F85 !important;
}
.inner-table-text {
  font-weight: 300;
}
.inner-table-header {
  border-bottom: 1px solid #F0F1FC;
}
.inner-item-table{
  border: 1px solid #F0F1FC;
  border-radius: 4px;
}
/* #table-materiais {
  display: flex;
  flex-direction: column;
  border: 1px solid #F0F1FC ;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
} */
.each-material {
  padding: 4px;
  display: flex;
  width: 100%;
  border: 1px solid #F0F1FC;
  border-radius: 10px;
  flex-direction: column;
  border-collapse: collapse;
}
.each-material:not(.material-opened):hover {
  background: #F7F7FA;
}
.each-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.text-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
}
.each-material-right {
  display: flex;
}
.each-material-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.box-icon-click:hover {
  background-color: #F0F1FC;
  background-size: 4;
  cursor: pointer;
}
.detalhe {
  margin-top: 25px;
  margin: 12px;
  display: flex;
  border: 1px solid #F0F1FC ;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;
}
.detalhe-header {
  display: flex;
  border-collapse: collapse;
  border: 1px solid #F0F1FC;
}
.header-span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.each-detalhe-div {
  display: flex;
  padding: 10px;
}
.detalhe-data {
  display: flex;
}
.each-material:hover .mat-hover-visibility {
  visibility: visible;
}
.icons-color {
  color: #7E829F;
}
#item-menu {
  position: absolute;
  right: 20px;
  top: 40px;
  z-index: 2;
  width: 300px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid rgb(238, 238, 238);
}
.item-menu-option {
  display: block;
  font-size: 0.9rem;
  padding: 15px;
}
</style>
