<template>
  <main class="container padding">
    <div class="header">
      <label class="title">Protocolo de devolução - Material Médico</label>
      <img src="/logo.png" alt="" class="modal-logo" width="150px" />
    </div>
    <div class="w">
      <b-row>
        <b-col>
          <label>Protocolo:</label>
          <div class="info">
            {{ relatorioData.siclaProtocolo }}
          </div>
          <br />

          <label>Responsável CME:</label>
          <div class="info">
            {{ relatorioData.usuario ? relatorioData.usuario.nome : "-" }}
          </div>
          <br />
        </b-col>

        <b-col>
          <label>Data/Hora:</label>
          <br />
          <div class="info">
            {{ getDateTime(relatorioData.dateTime) }}
          </div>
          <br />

          <label>Responsável retirada:</label>
          <div class="info">
            {{ relatorioData.nomeRetiradaMaterialMedico || "-" }}
          </div>
          <br />
        </b-col>

        <b-col>
          <label>Unidade:</label>
          <br />
          <div class="info">
            {{ relatorioData.unidade ? relatorioData.unidade.nome : "-" }}
          </div>
          <br />
          <label>RG responsável retirada:</label>
          <br />
          <div class="info">
            {{ relatorioData.rgRetiradaMaterialMedico || "-" }}
          </div>
          <br />
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        hover
        :items="relatorioData.materiais"
        :fields="fields"
        class="mt-3 db"
        responsive
      >
        <template #cell(index)="{ index }">
          {{ index + 1 }}
        </template>

        <template #row-details="row">
          <b-card>
            <b-row>
              <b-col>Justificativa: {{ row.item.justificativa }}</b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
    </div>
  </main>
</template>

<script>
import moment from "moment";

export default {
  name: "RelatorioDevolucaoMedico",
  props: {
    relatorioData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "id",
          label: "ID",
        },
        {
          key: "descricao",
          label: "Descrição",
        },
        {
          key: "cirurgiao",
          label: "Cirurgião",
        },
        {
          key: "quantidade_itens",
          label: "Qde peças cadastradas",
        },
        {
          key: "quantidade_pecas",
          label: "Qde peças devolvidas",
        },
      ],
    };
  },

  methods: {
    getDateTime(dateTime) {
      return moment(dateTime).local().format("DD/MM/YYYY HH:mm:ss");
    },
  },
};
</script>

<style scoped>
* {
  font-weight: 400;
  font-size: 16px;
}

.padding {
  padding: 36px;
  font-weight: 400;
}

label {
  margin-bottom: 3px;
}

.info {
  margin-bottom: 12px;
}

.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}

.div-assinaturas {
  display: flex;
  margin-bottom: 10px;
}

.w h2 {
  font-size: 16px;
  font-weight: 600;
}

.modal-header {
  border-bottom: 0;
}

.header .modal-logo {
  position: absolute;
  right: 20px;
  top: 20px;
  margin-top: 10px;
}

.header .title {
  font-weight: 500;
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 40px;
  margin-left: -10px;
}
</style>
