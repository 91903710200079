<template>
  <div>
    <Modal
      id="modalPhoto-leitura"
      ref="modalPhotoLeitura"
      nome="modalPhoto-leitura"
      title="Fotos do Material"
      size="md"
      centered
    >
      <PhotoForm
        :photos="fotosMaterial"
        downloadRoute="material/downloadFotos"
        onlyVisualize
      />
    </Modal>
    <div class="text-center">
      <div v-if="isBusy" class="loading-block">
        <div class="loading-background" />
        <b-spinner class="spinner"></b-spinner>
      </div>
      <b-form-group :label="!hideHeader ? 'Método de leitura' : ''">
        <b-form-radio-group
          id="btn-radios-1"
          v-model="inputType"
          :options="inputOptions"
          button-variant="primary-group"
          buttons
          class="radio-group"
        />
      </b-form-group>

      <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
      <div v-else>
        <div v-if="inputType === 'camera'">
          <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
          <br />
        </div>
        <div v-else-if="inputType === 'reader'">
          <b-form-group label-for="qrcode">
            <b-form-input
              v-model="currentQrCode"
              name="reader"
              @input="readerInput"
              ref="qr-code-reader-input"
              class="invision-input"
              id="qrcode"
              v-validate="{ required: false }"
            />
          </b-form-group>
          <br />
        </div>
        <div v-else-if="inputType === 'id'">
          <b-input-group>
            <b-form-input
              v-model="currentId"
              name="id"
              @keyup.enter="readerInput"
              ref="id-reader-input"
              class="invision-input"
              id="id"
              placeholder="Digite o ID do material"
              v-validate="{ required: false }"
            />
            <b-input-group-append>
              <Button
                type="outline"
                class="inlineBtn"
                @click="readerInput"
                text="Buscar"
                :loading="isBusy"
              />
            </b-input-group-append>
          </b-input-group>
          <br />
        </div>
        <div v-else-if="inputType === 'tipo'" :class="loadingTipo && 'opac-30'">
          <div v-if="loadingTipo" class="loading-block">
            <div class="loading-background" />
            <b-spinner class="spinner"></b-spinner>
          </div>
          <div class="flex-row">
            <b-form-select
              class="invision-input mr-1 w-30"
              ref="selectTipoMaterialGenerico"
              name="tipo_material_generico"
              :value="idTipoMaterialGenerico"
              @input="tipoMaterialChanged"
              :options="tipoMaterialGenericoOptions"
            >
              <template slot="first">
                <option :value="null">
                  -- Selecione uma opção --
                </option>
              </template>
            </b-form-select>
            <v-select
              class="invision-select-typing w-70"
              placeholder="-- Selecione uma opção --"
              ref="selectMaterialGenerico"
              name="material_generico"
              v-model="materialGenericoSelected"
              :options="materiaisGenericos"
            >
              <template #no-options>
                <em style="opacity: 0.5">Nenhuma opção disponível</em>
              </template>
            </v-select>
          </div>
          <div class="flex-row">
            <b-form-input
              class="mt-1 mr-1 w-20"
              type="number"
              placeholder="Qtd"
              min="1"
              v-model="qtMaterialGenerico"
            />
            <Button
              class="mt-1 mb-3 w-100"
              text="Confirmar"
              type="outline"
              @click="handleChooseMaterialGenerico"
              :disabled="!materialGenericoSelected || !qtMaterialGenerico"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import { mapState, mapActions } from 'vuex';
import Button from '../../../../../front-end/src/components/Utils/Button';
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';
import Modal from '../../../../../front-end/src/components/Utils/Modal';
import PhotoForm from '../../../../../front-end/src/components/WebCam/PhotoForm';

export default {
  components: {
    QrcodeStream,
    Button,
    GenericSelect,
    Modal,
    PhotoForm,
  },

  inject: [
    'onDetection',
    'addMaterialToModel',
    'chooseMaterialGenerico',
    'checkMaterialGenericoHasPreparo',
  ],
  data() {
    return {
      error: '',
      autoInputFocus: null,
      readerInputTimeout: null,
      currentQrCode: '',
      currentId: null,
      inputType: 'reader',
      idMaterialGenericoRead: null,
      idTipoMaterialGenerico: null,
      qtMaterialGenerico: null,
      tipoMaterialGenericoOptions: [],
      materiaisGenericos: [],
      fotosMaterial: [],
      loadingTipo: false,
      materialGenericoSelected: null,
    };
  },

  computed: {
    ...mapState(['noSubmitOnEnter', 'materiaisGenericosByTipo']),
    inputOptions() {
      const options = [{ text: 'Leitor', value: 'reader' }];
      if (!this.hideCamera) {
        options.push({ text: 'Câmera', value: 'camera' });
      }
      if (!this.hideId) {
        options.push({ text: 'ID', value: 'id' });
      }
      if (!this.hideMateriaisGenerico) {
        options.push({ text: 'Tipo', value: 'tipo' });
      }
      return options;
    },
  },

  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: true,
    },
    materiaisByProtocolos: {
      type: Array,
      required: false,
    },
    hideCamera: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideMateriaisGenerico: {
      type: Boolean,
      default: false,
    },
    hideId: {
      type: Boolean,
      default: false,
    },
    sendToModel: {
      type: Boolean,
      default: false,
    },
    checkFluxoMaterialGenerico: {
      type: Boolean,
      default: false,
    },
    disableInitFocus: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
    },
  },

  mounted() {
    if (!this.disableInitFocus) {
      this.autoFocus();
    }
  },

  watch: {
    inputType: {
      async handler(val) {
        this.error = '';
        if (val === 'reader') {
          setTimeout(this.autoFocus, 200);
        }
        if (val === 'tipo') {
          if (!this.materiaisGenericosByTipo) {
            this.loadingTipo = true;
            await this.getMateriaisGenericosByTipo();
          }
          this.fillTipoMaterialGenericoOptions();
          if (this.idMaterialGenericoRead) {
            this.setMaterialGenericoFromRead();
          }
          this.loadingTipo = false;
        }
      },
    },
  },

  methods: {
    ...mapActions(['getMateriaisGenericosByTipo']),
    async onDecode(value, isId = false) {
      this.currentQrCode = '';
      if (!this.isBusy && value) {
        const result = await this.onDetection({ value, type: isId ? 'id' : 'code' });
        let isMaterialMedico = false
        const isRecebimento = this.model.id_tipo_processo === 1

        if (result) {
          if (result.isMaterialGenerico) {
            this.inputType = 'tipo';
            this.idTipoMaterialGenerico = result.id_tipo_material_generico;
            this.idMaterialGenericoRead = result.id_material_generico;
            if (this.materiaisGenericosByTipo) {
              this.setMaterialGenericoFromRead();
            }
            return;
          }

          if(result.id_tipo_material === 2) isMaterialMedico = true

          const materialMedicoAndRecebimento = isRecebimento && isMaterialMedico

          if (this.sendToModel && !materialMedicoAndRecebimento) {
            this.addMaterialToModel();
            this.$emit('added-to-model');
          } else {
            this.$bvModal.show('modal-processo-protocolo')
          }
          if (
            this.config
            && this.config.showPicsAfterRead
            && result.fotoMaterial
            && result.fotoMaterial.length
          ) {
            this.fotosMaterial = result.fotoMaterial;
            setTimeout(() => this.$refs.modalPhotoLeitura.show(), 500);
          }
          this.autoFocus();
        }
      }
    },
    autoFocus() {
      if (this.$refs['qr-code-reader-input']) {
        this.$refs['qr-code-reader-input'].$el.focus();
      }
    },
    readerInput(input) {
      if (this.currentId && this.currentId[0] === 't') {
        const id_material = "T" + this.currentId.substr(1);
        this.currentId = id_material
      }

      this.$store.commit('setNoSubmitOnEnter', true);
      clearTimeout(this.readerInputTimeout);
      if (typeof input !== 'string') {
        // input é evento de click do botão qnd input de ID
        if (!(this.currentId)) {
          this.$store.commit('setNoSubmitOnEnter', false);
          return;
        }
        this.onDecode(this.currentId, true);
        this.currentId = null;
      } else {
        this.readerInputTimeout = setTimeout(
          this.onDecode,
          500,
          input,
        );
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson';
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        }
      }
    },
    fillTipoMaterialGenericoOptions() {
      this.tipoMaterialGenericoOptions = this.materiaisGenericosByTipo.reduce((acc, tmg) => {
        if (!tmg.tipoProcesso.some((tp) => tp.id_tipo_processo === this.model.id_tipo_processo)) return acc;
        return [
          ...acc,
          { text: tmg.nome, value: tmg.id_tipo_material_generico },
        ];
      }, []);
      if (this.tipoMaterialGenericoOptions.length === 1) {
        this.tipoMaterialChanged(this.tipoMaterialGenericoOptions[0].value);
      }
    },
    setMaterialGenericoFromRead() {
      this.materialGenericoSelected = this.materiaisGenericosByTipo
        .find((tmg) => tmg.id_tipo_material_generico === this.idTipoMaterialGenerico)
        .materialGenerico
        .find((mg) => mg.id_material_generico === this.idMaterialGenericoRead);
    },
    tipoMaterialChanged(v) {
      this.idTipoMaterialGenerico = v;
      this.materialGenericoSelected = null;
      if (v) {
        const tmg = this.materiaisGenericosByTipo.find(
          (tmg) => tmg.id_tipo_material_generico === v
        );

        this.materiaisGenericos = tmg.materialGenerico.map((mg) => ({
          ...mg,
          tipoMaterialGenerico: {
            nome: tmg.nome,
            id_tipo_material_generico: tmg.id_tipo_material_generico,
          },
        }));
      } else {
        this.materiaisGenericos = [];
        this.materialGenerico = null;
      }
    },
    async handleChooseMaterialGenerico() {
      if(
        this.model.tipo_processo !== 'recebimento'
        && this.model.numProtocolosRecebimento
        && !this.model.numProtocolosRecebimento.length
      ) {
        swal({
          icon: 'error',
          title: 'Erro',
          text: 'Para realizar este processo do material genérico por favor volte e insira o número de protocolo correspondente',
          buttons: { confirm: 'Fechar' },
        });
        return false;
      }
      if (!this.idTipoMaterialGenerico || !this.materialGenericoSelected || !this.qtMaterialGenerico) {
        return;
      }

      if(this.config && this.config.sendProcessoCicloRecebimento === true) {
        const result = this.checkMaterialGenericoHasPreparo(this.materialGenericoSelected)
        if(!result) return;
      }

      const result = this.chooseMaterialGenerico(this.materialGenericoSelected, this.qtMaterialGenerico);
      if (!result) return;

      if (
        this.config
        && this.config.showPicsAfterRead
        && this.materialGenericoSelected.fotoMaterialGenerico
        && this.materialGenericoSelected.fotoMaterialGenerico.length
      ) {
        this.fotosMaterial = this.materialGenericoSelected.fotoMaterialGenerico;
        setTimeout(() => this.$refs.modalPhotoLeitura.show(), 500);
      }
      if (this.sendToModel && !result.needModalProcesso) {
        this.addMaterialToModel();
        this.$emit('added-to-model');
      } else {
        this.$bvModal.show('modal-processo-protocolo')
      }

      if (this.tipoMaterialGenericoOptions.length > 1) {
        this.idTipoMaterialGenerico = null;
        this.materiaisGenericos = [];
      }
      this.materialGenericoSelected = null;
      this.qtMaterialGenerico = null;
    },
  },
};
</script>

<style scoped>
.loading-block {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  text-align: center;
}

.loading-block .spinner {
  color: #209f85;
  width: 8rem;
  height: 8rem;
}

.loading-block .loading-background {
  position: absolute;
  background-color: #dddddd;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}
.radio-group {
  width: 100%;
}
.flex-row {
  display: flex;
  justify-content: space-between;
}
.inlineBtn {
  border: 1px solid #ced4da;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.w-30 {
  width: 30%;
}
.w-70 {
  width: 70%;
}
.w-20 {
  width: 20%;
}
.opac-30 {
  opacity: 0.3;
}
.spinner-tipo-generico {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
<style>
.invision-select-typing .vs__dropdown-toggle {
  height: 100%;
  padding: 0;
  border-radius: 7px;
  border: 1px solid #ced4da;
}
.invision-select-typing .vs__selected {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing .vs__search {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing .vs__dropdown-option {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
</style>
