export default {
  enableMaterialRede: true,
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  ensureCurrentItemQuantity: true,
  modelProcesso: {
    id_unidade_origem: null,
    id_setor_origem: null,
    responsavel_setor: '',
    id_unidade_destino: null,
    id_setor_destino: null,
  },
  validLengthInputText: (function () {
    const min = 3
    return {
      min,
      typeValidation: 'alpha_spaces',
      message: `Deve inserir o nome do responsável (deve ter mínimo ${min} letras, não deve começar com caracteres especiais nem espaços ou números)`
    }
  })(),
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Leitura dos materiais',
      subItems: [
        { title: 'Origem/Destino do material' },
      ],
    },
  ],
};
