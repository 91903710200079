export default {
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  modelProcesso: {
    id_tipo_embalagem: null,
    id_tipo_esterilizacao: null,
    id_equipamento: null,
    lote: '',
    validade: '',
  },
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Informações do preparo',
      subItems: [{ title: 'Informações do preparo' }],
    },
  ],
};
