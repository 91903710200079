export default {
  customButtonPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  modelProcesso: {},
  currentMaterialModelProcesso: {
    id_tipo_esterilizacao: null,
    tipoEsterilizacao: '',
    id_equipamento: null,
    lote: '',
    id_tipo_teste: null,
    colaborador_termodesinfeccao: '',
    validade: '',
    lote_esterilizante: '',
    validade_esterilizante: '',
    ciclo_urgencia: false,
  },
  pageList: [
    {
      title: 'Esterilização de manufaturas',
      subItems: [{ title: 'Informações da Esterilização / Desinfecção' }],
    },
  ],
};
