<template>
  <div>
    <div v-if="currentPage === 2">
      <UnidadeSetorDestino
        :model="model"
        destinoCentral
      />
      <LeituraMateriais
        :materiaisByProtocolos="materiaisByProtocolos"
        :sendToModel="false"
        :model="model"
        :isBusy="isBusy"
        hasOptions
        enableReimprimirEtiqueta
        hideMateriaisGenerico
        :hideCamera="false"
      >
        <template #tabela v-if="hasProtocoloRecebimento">
          <TabelaMateriaisProtocolo
            :isBusy="isBusy"
            :currentMaterialModel="currentMaterialModel"
            :materiaisByProtocolos="materiaisByProtocolos"
            :columns="['Descrição', 'ID', 'Peças']"
            :columnsKeys="['descricao', 'shownId', 'pecas']"
            :model="model"
            :config="config"
            modalTitle="Informações do Preparo"
          />
        </template>
        <template #modal>
          <ModalProcesso
            title="Informações do Preparo"
            :currentMaterialModel="currentMaterialModel"
            preserveData
            :config="config"
            :validationFields="[
              'equipamento_lavagem_automatizada',
              'colaborador_lavagem_automatizada',
              'lote_detergente_lavagem_automatizada',
              'naoFazerAutomatizada',
              'integrador_quimico',
              'observacoes',
              'justificativa',
              'quantidade_pecas',
              'tipo_embalagem',
              'lote',
              'limpeza_automatizada',
            ]"
          >
            <template #processo>
              <b-container>
                <b-row>
                  <b-col>
                  <InformacoesMaterial
                    :currentMaterialModel="currentMaterialModel"
                  />
                  </b-col>
                </b-row>
                <Separator />
                <b-row>
                  <b-col>
                  <LavagemAutomatizada
                    :isBusy="isBusy"
                    :currentMaterialModel="currentMaterialModel"
                  />
                  </b-col>
                </b-row>
                <Separator />
                <b-row>
                  <b-col>
                    <InformacoesPreparo
                      :isBusy="isBusy"
                      :currentMaterialModel="currentMaterialModel"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>

          </ModalProcesso>
        </template>
      </LeituraMateriais>
    </div>
    <b-modal
      id="modalEtiquetaPreparo"
      ref="modalEtiquetaPreparo"
      title="Imprimir Etiqueta"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      @hidden="goToPage(currentPage + 1, true)"
    >
      <PrinterEtiqueta
        :resultData="etiquetaData"
      >
        <template #printContent>
          <EtiquetaEntradaPreparo
            :relatorioData="etiquetaData"
          />
        </template>
      </PrinterEtiqueta>
    </b-modal>
    <Modal
      id="modalRelatorioTransfPreparo"
      ref="modalRelatorioTransfPreparo"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      @hidden="restartProcessamento"
    >
      <PrinterRelatorio
        buttonName="IMPRIMIR"
        hideSection="section"
        hideContent="content"
      >
        <template #printContent class="w">
          <RelatorioTransferenciaPreparo
            :relatorioData="relatorioData"
          />
        </template>
      </PrinterRelatorio>
    </Modal>
  </div>
</template>

<script>
import GenericApi from '../../../../../../front-end/src/services/genericRequest';
import Button from '../../../../../../front-end/src/components/Utils/Button';
import PrinterEtiqueta from '../../../../../../front-end/src/components/Utils/Printer';
import PrinterRelatorio from '../../../../../../front-end/src/components/Utils/PrinterRelatorio';
import Modal from '../../../../../../front-end/src/components/Utils/Modal';
import UnidadeSetorDestino from '../../Components/UnidadeSetorDestino';
import MaterialDetectionPage from '../../Components/MaterialDetectionPage';
import TabelaMateriais from '../../Components/TabelaMateriais';
import InformacoesPreparo from '../../Components/InformacoesPreparo';
import LavagemAutomatizada from '../../Components/LavagemAutomatizada';
import EtiquetaEntradaPreparo from '../EntradaPreparo/Etiqueta';
import RelatorioTransferenciaPreparo from '../TransferenciaPreparo/Relatorio';
import LeituraMateriais from '../../Components/LeituraMateriais'
import ModalProcesso from '../../Components/ModalProcesso'
import InformacoesMaterial from '../../Components/InformacoesMaterial'
import Separator from '../../../../../../front-end/src/components/Utils/Separator'
import TermoLavagemAuto from '../../Components/TermoLavagemAuto'
import TabelaMateriaisProtocolo from '../../Components/TabelaMateriaisProtocolo'
import config from './config'

export default {
  name: 'EntradaTransferenciaPreparo',
  inject: [
    'returnToPreviousPage',
    'goToPage',
    'preserveCurrentMaterialModel',
    'changeBusy',
    'discardForm',
    'addMaterialToModel',
    'restartProcessamento',
    'validatePageChange',
    'getMateriaisByProtocolo',
  ],
  components: {
    Button,
    UnidadeSetorDestino,
    MaterialDetectionPage,
    LavagemAutomatizada,
    InformacoesPreparo,
    TabelaMateriais,
    PrinterEtiqueta,
    PrinterRelatorio,
    Modal,
    EtiquetaEntradaPreparo,
    RelatorioTransferenciaPreparo,
    LeituraMateriais,
    ModalProcesso,
    InformacoesMaterial,
    TermoLavagemAuto,
    Separator,
    TabelaMateriaisProtocolo
  },
  data() {
    return {
      etiquetaData: {},
      relatorioData: {},
      config
    };
  },
  props: {
    materiaisByProtocolos: {
      type: Array,
      required: false,
    },
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
    },
  },
  computed: {
    hasProtocoloRecebimento() {
      return this.model.numProtocolosRecebimento && this.model.numProtocolosRecebimento[0];
    }
  },
  methods: {
    justConfirm() {
      this.preserveCurrentMaterialModel({});
      this.confirmPreparo();
    },
    confirmContinue() {
      this.preserveCurrentMaterialModel({
        id_tipo_embalagem: this.currentMaterialModel.id_tipo_embalagem,
        integrador_quimico: this.currentMaterialModel.integrador_quimico,
        observacoes: this.currentMaterialModel.observacoes,
        lavagem_automatizada: this.currentMaterialModel.lavagem_automatizada,
        colaborador_lavagem_automatizada: this.currentMaterialModel
          .colaborador_lavagem_automatizada,
        id_equipamento_lavagem_automatizada: this.currentMaterialModel
          .id_equipamento_lavagem_automatizada,
        lote_detergente_lavagem_automatizada: this.currentMaterialModel
          .lote_detergente_lavagem_automatizada,
        naoFazerAutomatizada: this.currentMaterialModel.naoFazerAutomatizada,
      });
      this.confirmPreparo();
    },
    async confirmPreparo() {
      const newModel = {
        ...this.model,
        tipo_processo: 'entradaPreparo',
        materiais: [{ ...this.currentMaterialModel }],
      };

      try {
        if (!(await this.validatePageChange())) return;
        this.changeBusy(true);

        delete newModel.materiais[0].camposMaterial;

        const result = await GenericApi.create(newModel, 'formularios');

        this.addMaterialToModel();
        swal({
          title: 'Bom trabalho!',
          text: 'O registro foi criado na base de dados com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });

        this.etiquetaData = result;
        this.$nextTick(() => {
          this.$refs.modalEtiquetaPreparo.show();
        });
      } catch (error) {
        let reqErrorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          reqErrorMessage = error.response.data.error.reqErrorMessage;
        }

        await swal({
          title: 'Oops!',
          text:
            reqErrorMessage || 'Ocorreu um problema ao tentar criar o registro',
          icon: 'error',
          button: 'Continuar',
        });
      } finally {
        this.changeBusy(false);
      }
    },
    async confirmTransferencia() {
      this.changeBusy();
      const newModel = {
        ...this.model,
        tipo_processo: 'transferenciaPreparo',
      };
      try {
        newModel.materiais.forEach((material) => {
          delete material.camposMaterial;
        });
        newModel.tipo_processo = 'transferenciaPreparo';
        const result = await GenericApi.create(newModel, 'formularios');

        swal({
          title: 'Bom trabalho!',
          text: 'O registro foi criado na base de dados com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });

        this.relatorioData = result;
        this.$nextTick(() => {
          this.$refs.modalRelatorioTransfPreparo.show();
        });
      } catch (error) {
        let reqErrorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          reqErrorMessage = error.response.data.error.reqErrorMessage;
        }

        await swal({
          title: 'Oops!',
          text:
            reqErrorMessage || 'Ocorreu um problema ao tentar criar o registro',
          icon: 'error',
          button: 'Continuar',
        });
      } finally {
        this.changeBusy();
      }
    },
  },
  watch: {
    currentPage: {
      immediate: true,
      async handler(newPage, oldPage) {
        if (newPage === 2 && this.hasProtocoloRecebimento) {
          const { numProtocolosRecebimento } = this.model;

          await this.getMateriaisByProtocolo(numProtocolosRecebimento, { processoCheck: 'preparo', 
          ignoreValidationProtocolo: true,
          validateFluxo: true,
          sub_setor: this.model.sub_setor,
          ignoreValidationResults: true,  
          id_unidade: this.model.id_unidade,           
          });
        }
      },
    },
  },
};
</script>
