<template>
  <b-row align-h="between" class="pt-4">
    <b-col>
      <Button
        v-if="currentPage > 0"
        text="Voltar"
        variant="grayscale"
        type="text"
        @click="returnToPreviousPage"
        :loading="isBusy"
      />
    </b-col>
    <b-col class="text-right">
      <Button
        :text="confirmButtonText"
        @click="confirmPage"
        :loading="isBusy"
      />
    </b-col>
  </b-row>
</template>
<script>
import Button from '../../../../../front-end/src/components/Utils/Button';

export default {
  name: 'Buttons',
  inject: ['confirmPage', 'returnToPreviousPage'],
  components: {
    Button,
  },
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    confirmButtonText() {
      return this.numberOfPages === this.currentPage + 1 ? 'Finalizar' : 'Confirmar';
    },
  },
};
</script>
