export default {
  customButtonPages: [2, 3],
  showBannerPages: [3],
  underListPages: [],
  tabelaMateriaisPage: null,
  allowFetchMateriaisWithoutProtocolo: false,
  modelProcesso: {
    numProtocolosRecebimento: [],
  },
  needProtocoloRecebimento: false,
  tipoProtocolo: 'recebimento',
  manyProtocolosRecebimento: false,
  showPicsAfterRead: true,
  currentMaterialModelProcesso: {
    observacoes: '',
    quantidade_pecas: null
  },
  pageList: [
    {
      title: 'Manutenção de materiais',
      subItems: [{ title: 'Informação dos materiais' }],
    },
  ],
};
