<template>
  <div>
    <b-row class="input-p">
      <b-col sm="12" md="6" lg="4" xl="4">
        <b-form-group
          :label="`Tipo de ${tipoMaterialGenerico}*`"
          label-for="idMaterialGenerico"
        >
          <GenericSelect
            id="idMaterialGenerico"
            name="idMaterialGenerico"
            route="materialGenerico"
            labelKey="label"
            :value="currentMaterialModel.id_material_generico"
            @input="val => updateCurrentMaterialModel('id_material_generico', val)"
            :customFilters="{tipoMaterialGenerico}"
            v-validate="{required:true}"
            :status="validateState('idMaterialGenerico')"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" xl="4">
        <b-form-group
          label="Quantidade*"
          label-for="qt_material_generico"
        >
          <b-form-input
            id="qt_material_generico"
            name="qt_material_generico"
            key="qt_material_generico"
            type="number"
            :value="currentMaterialModel.qt_material_generico"
            @input="val => updateCurrentMaterialModel('qt_material_generico', val)"
            autocomplete="off"
            class="invision-input"
            :disabled="isBusy"
            v-validate="{required: true, numeroMaximoDeMateriaisEnxovaisEsterilizados: [configuracao.numeroMaximoDeMateriaisEnxovaisEsterilizados]}"
            :state="validateState('qt_material_generico')"
          />
          <b-form-invalid-feedback>
            A quantidade de materiais esterilizados não pode exceder {{ configuracao.numeroMaximoDeMateriaisEnxovaisEsterilizados }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" xl="4">
        <b-form-group label="Embalagem*" label-for="tipo_embalagem">
          <GenericSelect
            name="tipo_embalagem"
            labelKey="nome"
            :value="currentMaterialModel.id_tipo_embalagem"
            route="tipoEmbalagem"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('tipo_embalagem')"
            @input="val => updateCurrentMaterialModel('id_tipo_embalagem', val)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" xl="4">
        <b-form-group
          label="Tipo de Processamento*"
          label-for="tipoProcessamento"
        >
          <GenericSelect
            ref="selectTipoProcessamento"
            name="tipoProcessamento"
            labelKey="nome"
            :value="currentMaterialModel.id_tipo_esterilizacao"
            route="tipoEsterilizacao"
            :disabled="forcedSelection || isBusy"
            v-validate="{ required: true }"
            :state="validateState('tipoProcessamento')"
            @input="handleChangeIdEsterilizacao"
            @filled="handleFilled"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" xl="4">
        <b-form-group
          label="Equipamento Esterilização*"
          label-for="equipamento"
        >
          <GenericSelect
            ref="selectEquipamento"
            name="equipamento"
            labelKey="nome"
            :value="currentMaterialModel.id_equipamento"
            :customFilters="equipamentoEsterilizacaoFilters"
            route="equipamento"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('equipamento')"
            @input="val => updateCurrentMaterialModel('id_equipamento', val)"
            @filled="handleFilled"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" xl="4">
        <b-form-group
          label="Lote Equipamento*"
          label-for="lote"
        >
          <b-form-input
            id="lote"
            :value="currentMaterialModel.lote"
            @input="val => updateCurrentMaterialModel('lote', val)"
            autocomplete="off"
            class="invision-input"
            name="lote"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('lote')"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" xl="4">
        <b-form-group
          label="Tipo de Teste*"
          label-for="tipoTeste"
        >
          <GenericSelect
            ref="selectTipoTeste"
            name="tipoTeste"
            labelKey="nome"
            :value="currentMaterialModel.id_tipo_teste"
            @input="val => updateCurrentMaterialModel('id_tipo_teste', val)"
            route="tipoTeste"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('tipoTeste')"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" xl="4">
        <br />
        <b-form-checkbox
          id="ciclo_urgencia"
          name="ciclo_urgencia"
          placeholder="Ciclo de Urgência"
          :checked="currentMaterialModel.ciclo_urgencia"
          @input="val => updateCurrentMaterialModel('ciclo_urgencia', val)"
          :state="validateState('ciclo_urgencia')"
        >
          Ciclo de Urgência
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';
import { mapState } from 'vuex';

export default {
  components: {
    GenericSelect,
  },
  inject: ['parentValidator', 'validateState', 'updateCurrentMaterialModel'],
   computed: {
    ...mapState(['subSetores', 'configuracao']),
  },
  created() {
    this.$validator = this.parentValidator;
  },
  data() {
    return {
      forcedSelection: false,
      counterRequests: 0
    };
  },
  props: {
    model: {
      type: Object,
    },
    tipoMaterialGenerico: {
      type: String,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
    },
    isBusy: {
      type: Boolean,
    },
    equipamentoEsterilizacaoFilters: {
      type: Object,
    },
  },
  methods: {
    handleChangeIdEsterilizacao(val) {
      const esterilizacao = this.$refs.selectTipoProcessamento.apiResponse.find(
        (item) => item.id_tipo_esterilizacao === val,
      );
      this.updateCurrentMaterialModel(['id_tipo_esterilizacao','tipoEsterilizacao'] , [val, esterilizacao.chave]);
    },
    handleFilled(){
      this.counterRequests++;
      if(this.tipoMaterialGenerico === "enxoval" && this.counterRequests === 2){
        this.handleChangeIdEsterilizacao(1);
        this.forcedSelection = true;
      }
    }
  },
};
</script>
