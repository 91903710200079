export default {
  enableMaterialRede: true,
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  enableMaterialRede: true,
  modelProcesso: {
    sala: null,
    aviso: null,
  },
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Informações',
      subItems: [
        { title: 'Saída para paciente' },
      ],
    },
  ],
};
