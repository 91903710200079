<template>
  <div>
    <div id="modal-body-wrapper">
      <div id="printSection">
        <slot name="printContent" :isPrinting="isPrinting" />
      </div>
    </div>
    <b-button
      type="button"
      variant="none"
      class="text-white primary-contained-button"
      @click="printContent"
      >{{ buttonName }}</b-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPrinting: false,
    };
  },
  props: {
    buttonName: {
      type: String,
      default: 'Imprimir',
    },
    hideSection: {
      type: String,
    },
    hideContent: {
      type: String,
    },
    withoutPagination: {
      type: Boolean,
    },
  },

  methods: {
    printContent() {
      this.isPrinting = true;
      this.$nextTick(() => {
        if (this.withoutPagination) {
          this.printContentWithoutPagination();
          this.isPrinting = false;
          return;
        }
        document.getElementById(this.hideContent).style.display = 'none';

        const printDiv = document.createElement('div');
        const printSection = document.getElementById('printSection');
        printDiv.appendChild(printSection);
        document.body.appendChild(printDiv);

        window.print();

        printDiv.remove();
        document.getElementById(this.hideContent).style.display = 'block';
        document.getElementById('modal-body-wrapper').appendChild(printSection);
        this.isPrinting = false;
      });
    },

    printContentWithoutPagination() {
      this.$emit('change-pagination');
      this.$nextTick(() => {
        document.getElementById(this.hideContent).style.display = 'none';

        const printDiv = document.createElement('div');
        const printSection = document.getElementById('printSection');
        printDiv.appendChild(printSection);
        document.body.appendChild(printDiv);

        window.print();

        printDiv.remove();
        document.getElementById(this.hideContent).style.display = 'block';
        document.getElementById('modal-body-wrapper').appendChild(printSection);
        this.$emit('change-pagination');
      });
    },
  },
};
</script>

<style>
@media print {
  body * {
    visibility: hidden;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
