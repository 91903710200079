export default {
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [3, 4],
  tabelaMateriaisPage: 5,
  modelProcesso: {},
  currentMaterialModelProcesso: {
    quantidade_itens: null,
    quantidade_pecas: null,
    justificativa: '',
  },
  pageList: [
    {
      title: 'Leitura dos materiais',
      subItems: [
        { title: 'Validar QrCode' },
      ],
    },
  ],
};
