const idTipoMaterialGenericoEnxoval = 4;
const idSetorSpr = 51;
const idTipoEmbalagemSMS = 1;

export default {
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  modelProcesso: {
    materiais: [],
    id_tipo_material_generico: idTipoMaterialGenericoEnxoval,
    id_setor: idSetorSpr,
    id_tipo_embalagem: idTipoEmbalagemSMS,
  },
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Cadastro/Preparo',
      subItems: [{ title: 'Cadastro e preparo de enxovais' }],
    },
  ],
  idTipoMaterialGenericoEnxoval,
  idSetorSpr,
  idTipoEmbalagemSMS,
};
