<template>
  <div>
    <div v-if="currentPage === 2">
      <LavagemAutomatizada
        :isBusy="isBusy"
        :model="model"
      />
      <InformacoesPreparo
        :isBusy="isBusy"
        :model="model"
        forceHasQtdItens
      />
    </div>
    <div v-if="currentPage === 3">
      <LeituraMateriais
        :model="model"
        :isBusy="isBusy"
        sendToModel
        withoutTitle
        :materiaisByProtocolos="materiaisByProtocolos"
        hideMateriaisGenerico
        :hideCamera="false"
      >
        <template v-if="materiaisByProtocolos.length" #tabela>
          <TabelaMateriaisProtocolo
            :isBusy="isBusy"
            :currentMaterialModel="currentMaterialModel"
            :materiaisByProtocolos="materiaisByProtocolos"
            :columns="['Descrição', 'ID', 'Peças']"
            :columnsKeys="['descricao', 'shownId', 'pecas']"
            :model="model"
            modalTitle="Informações do Preparo"
            :enableEditInformacoes="false"
            :enableReimprimirEtiqueta="false"
          />
        </template>
      </LeituraMateriais>
    </div>
  </div>
</template>

<script>
import LavagemAutomatizada from '../../Components/LavagemAutomatizada';
import InformacoesPreparo from '../../Components/InformacoesPreparo';
import LeituraMateriais from '../../Components/LeituraMateriais';
import TabelaMateriaisProtocolo from '../../Components/TabelaMateriaisProtocolo'

export default {
  name: 'PreparoLote',
  inject: ['getMateriaisByProtocolo', 'returnToPreviousPage', 'setMateriaisByProtocolos'],
  components: {
    LavagemAutomatizada,
    InformacoesPreparo,
    LeituraMateriais,
    TabelaMateriaisProtocolo
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true
    },
    materiaisByProtocolos: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasProtocoloRecebimento() {
      return this.model.numProtocolosRecebimento && this.model.numProtocolosRecebimento[0];
    }
  },
  methods: {
    customOnDetection(currentMaterialModel, model) {
      if (!model.id_tipo_material) {
        model.id_tipo_material = currentMaterialModel.id_tipo_material;
      }
    },
    checkQtdPecas(materiais) {
      return materiais.every(material =>
        material.pecas === Number(this.model.quantidade_pecas)
      )
    },
    checkAllMateriaisSameTipo(materiais) {
      const tipo = materiais[0].tipoMaterial
      return materiais.every(material => material.tipoMaterial === tipo)
    }
  },
  watch: {
    currentPage: {
      immediate: true,
      async handler(newPage) {
        if (newPage === 3 && this.hasProtocoloRecebimento) {
          const { numProtocolosRecebimento } = this.model;

          await this.getMateriaisByProtocolo(
            numProtocolosRecebimento, 
            { 
              processoCheck: 'preparo',
              validateFluxo: true,
              ignoreValidationResults: true,
              sub_setor: this.model.sub_setor,
              id_unidade: this.model.id_unidade,
              checkSameQuantityPecas: true,
              checkSameTipo: true,
              quantidade_pecas: this.model.quantidade_pecas
            }
          );

          if(this.materiaisByProtocolos && Object.keys(this.materiaisByProtocolos).length) {
            const materiais = this.materiaisByProtocolos.reduce((acum, prot) => {
              return [...acum, ...prot.materiais]
            }, [])
            const allMateriaisPassedQtdPecas = this.checkQtdPecas(materiais)
            const allMateriaisPassedSameTipo = this.checkAllMateriaisSameTipo(materiais)

            let errorMessage = ''
            if(!allMateriaisPassedQtdPecas) {
              errorMessage = `Preparo em lote só pode ser realizado com materiais com a mesma quantidade de peças.
              Peças requeridas por cada material: ${this.model.quantidade_pecas}
              `
            }
            if(!allMateriaisPassedSameTipo) {
              errorMessage = `Preparo em lote só pode ser realizado com materiais do mesmo tipo`
            }
            if(errorMessage) {
              await swal({
                title: 'Erro',
                text: errorMessage,
                icon: 'error',
                button: 'Voltar',
              });
              if(this.materiaisByProtocolos.length) {
                this.setMateriaisByProtocolos([])
              }

              this.returnToPreviousPage()
            }
          }

        }
      },
    },
  }
};
</script>
