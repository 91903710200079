<template>
  <div>
    <!-- Modal Printer -->
    <Modal
      id="modalEtiqueta"
      ref="modalEtiqueta"
      nome="modalEtiqueta"
      title="Impressão de etiqueta"
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      centered
    >
      <b-container>
        <Printer
          buttonName="Imprimir etiqueta"
          :stringZebra="materialToPrint.stringZebra"
          fixedContent
        >
          <template #printContent class="qrCode-printer">
            <div id="qrCode" class="text-center qrCode-div">
              <qrcode-vue
                :value="materialToPrint.code"
                level="H"
                class="qr-code"
                renderAs="svg"
                size="75"
              ></qrcode-vue>
              <label class="qr-code-label mb-4 descricao-material">
                {{ labelQr }}
              </label>
              <br />
              <label class="qr-code-label mb-4 qrCode-INVCXCuuid no-print">
                {{ materialToPrint.code }}
              </label>
            </div>
          </template>
        </Printer>
      </b-container>
    </Modal>


    <div v-if="!withoutTitle">
      <br />
      <hr />
      <br />
      <b-row class="mb-4">
        <b-col>
          <h4 id="leitura-materiais-title">
            Leitura dos materiais
          </h4>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col cols=7>
        <MaterialDetection
          ref="MaterialDetection"
          :model="model"
          v-model="code"
          :isBusy="isBusy"
          name="qrCode"
          v-validate="{ required: true }"
          :state="validateState('qrCode')"
          hideHeader
          :hideCamera="hideCamera"
          :hideId="hideIdDetection"
          :hideMateriaisGenerico="hideMateriaisGenerico"
          :sendToModel="sendToModel"
          :disableInitFocus="disableInitFocus"
          @added-to-model="addedToModel"
          :materiaisByProtocolos="materiaisByProtocolos"
          :config="config"
        />
      </b-col>
      <b-col cols=1 />
      <b-col cols=4 class="div-but-finalizar">
        <br />
        <Button
          text="Finalizar"
          :disabled="!model.materiais.length"
          @click="handleFinish"
          class="but-finalizar"
        />
      </b-col>
    </b-row>
    <slot name="tabela">
      <TabelaLeituraMateriais
        :model="model"
        :showPertenceA="showPertenceA"
        :detalheMaterial="detalheMaterial"
        :showDetalhes="showDetalhes"
        :hasIntegridade="hasIntegridade"
        :hasOptions="hasOptions"
        :enableReimprimirEtiqueta="enableReimprimirEtiqueta"
      />
    </slot>
    <!-- Modal Processo -->
    <slot name="modal" />

    <Button
      class="mt-3"
      variant="grayscale"
      type="contained"
      @click="cancelLeitura"
      text="Cancelar"
    />
  </div>
</template>

<script>
import Button from '../../../../../front-end/src/components/Utils/Button';
import MaterialDetection from './MaterialDetection';
import TabelaLeituraMateriais from './TabelaLeituraMateriais'
import Printer from '../../../../../front-end/src/components/Utils/Printer'
import Modal from '../../../../../front-end/src/components/Utils/Modal'
import QrcodeVue from 'qrcode.vue';
import { mapState } from 'vuex';

export default {
  name: 'LeituraMateriais',
  provide() {
    return {
      updateShowDetalhes: this.updateShowDetalhes,
      handleReimprimirEtiqueta: this.handleReimprimirEtiqueta,
      showModal: this.showModal,
      hideModal: this.hideModal,
      handleIntegridade: this.handleIntegridade,
    }
  },
  inject: [
    'validatePageChange',
    'validateState',
    'confirmPage',
    'setCurrentMaterialFromModel',
    'restartProcessamento',
    'checkMateriaisMedicosBeforeSave',
  ],
  components: {
    Button,
    MaterialDetection,
    TabelaLeituraMateriais,
    Printer,
    Modal,
    QrcodeVue
  },
  data() {
    return {
      code: '',
      showDetalhes: {},
      materialToPrint: {},
    };
  },
  props: {
    beforeFinish: {
      type: Array,
      required: false,
    },
    setIsIntegridade: {
      type: Function || undefined,
      required: false,
      default: () => {}
    },
    hideCamera: {
      type: Boolean,
      required: false,
      default: true
    },
    materiaisByProtocolos: {
      type: Array,
      required: false,
    },
    sendToModel: {
      type: Boolean,
      required: false,
      default: () => true
    },
    model: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    withoutTitle: {
      type: Boolean,
      default: false,
    },
    detalheMaterial: {
      type: Boolean,
      default: false,
    },
    showPertenceA: {
      type: Boolean,
      default: false,
    },
    hasIntegridade: {
      type: Boolean,
      default: false,
      required: false
    },
    hasOptions: {
      type: Boolean,
      default: false,
      required: false
    },
    hideMateriaisGenerico: {
      type: Boolean,
      default: false,
    },
    enableReimprimirEtiqueta: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
    },
    hideIdDetection: {
      type: Boolean,
      default: false,
    },
    disableInitFocus: {
      type: Boolean,
      default: true,
    },
    setIsGeraProcedimento: {
      type: Function || undefined,
      required: false,
      default: () => {}
    },
  },
  computed: {
    ...mapState(['currentUnidade', 'genericData']),

    labelQr() {
      if(this.materialToPrint) {
        const { materialToPrint } = this
        let nomeMedico, nomeFornecedor, id, descricao

        if (!materialToPrint.descricao) return '';

        if(materialToPrint.id) {
          id = `${materialToPrint.id} - `
        } else {
          id = ''
        }

        if(materialToPrint.descricao) {
          if(materialToPrint.descricao.length < 60) {
          descricao = materialToPrint.descricao
          } else {
          descricao = materialToPrint.descricao.substr(0, 57).concat('...')
          }
        } else {
          descricao = ''
        }

        if(materialToPrint.medico) {
          if(materialToPrint.medico.nome.length < 40) {
          nomeMedico = materialToPrint.medico.nome
          } else {
          nomeMedico = materialToPrint.medico.nome.substr(0, 37).concat('...')
          }
        } else {
          nomeMedico = ' - '
        }

        if(materialToPrint.fornecedor) {
          if(materialToPrint.fornecedor.nome.length < 40) {
          nomeFornecedor = materialToPrint.fornecedor.nome
          } else {
          nomeFornecedor = materialToPrint.fornecedor.nome.substr(0, 37).concat('...')
          }
        } else {
          nomeFornecedor = ''
        }

          return `${id}${descricao} ${nomeMedico}${nomeFornecedor}`;
      }
    },
  },
  methods: {
    handleIntegridade(mat) {
      this.$emit('integridade', mat)
      this.setIsIntegridade(true)

      if (mat.needProcedimento) {
        this.setIsGeraProcedimento(true)
        this.setIsIntegridade(false)
      } else {
        this.setIsGeraProcedimento(false)
      }

      let indexMaterialToIntegridade = null;

      if (mat.id_material_generico) {
        indexMaterialToIntegridade = this.model.materiais.findIndex(material => material.id_material_generico === mat.id_material_generico);
      } else {
        indexMaterialToIntegridade = this.model.materiais.findIndex(material => material.id_material === mat.id_material);
      }

      if(indexMaterialToIntegridade || indexMaterialToIntegridade === 0) {
        this.setCurrentMaterialFromModel(indexMaterialToIntegridade)
        this.showModal()
      }
    },
    showModal() {
      this.$bvModal.show('modal-processo-protocolo');
    },
    hideModal() {
      this.$bvModal.hide('modal-processo-protocolo');
    },
    handleReimprimirEtiqueta(materialObj) {
      const materialToPrint = this.model.materiais.find(m => m.shownId === materialObj.shownId)
      if(materialToPrint) {
        this.materialToPrint = materialToPrint
        this.$refs.modalEtiqueta.show()
      }
    },
    async handleFinish() {
      // Se tem material médico com saida de bloco
      // ativa solicitação de registro de supervisor
      if (this.model.tipo_processo === 'saidaSetor') {
        if (this.config.checkMaterialMedicoSaidaBloco) {
          const destinoUnidadeSetor = [
            this.model.id_unidade_destino, this.model.id_setor_destino
          ]
          const destinoUnidade = this.genericData.unidade.filter(unidade => unidade.id_unidade === destinoUnidadeSetor[0]);
          const destinoSetor = destinoUnidade[0].setor.filter(setor => setor.id_setor === destinoUnidadeSetor[1]);
          const materiaisMedicos = this.model.materiais.filter((material)=>
            material.tipoMaterial && material.tipoMaterial.chave === 'medico'
          )
          if (materiaisMedicos && materiaisMedicos.length && !destinoSetor[0].UnidadeSetor.bloco ) {
            this.checkMateriaisMedicosBeforeSave()
            return
          }
        }
      } // ***

      const needBeforeFinish = this.beforeFinish && this.beforeFinish.length;
      if (needBeforeFinish) {
        const functionsToExecute = this.beforeFinish
          .filter((obj) => obj.condition)
          .map((obj) => obj.fn());

        const promises = await Promise.all(functionsToExecute);
        const result = promises.every((boolean) => boolean);

        if (!result) return;
      }

      const validation = await this.validatePageChange();
      if (!validation) return;
      const confirmation = await swal({
        title: 'Confirmação',
        text: 'Deseja finalizar o processo?',
        buttons: { cancel: 'Cancelar', confirm: 'Sim' },
        icon: 'warning',
      });
      if (confirmation) {
        this.confirmPage();
      }
    },
    async cancelLeitura() {
      const confirmation = await swal({
        text: 'Tem certeza que deseja cancelar todo o processo?',
        title: 'Confirmação',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });
      if (confirmation) {
        this.restartProcessamento();
      }
    },
    addedToModel() {
      this.showDetalhes = {
        0: true,
      };
    },
    updateShowDetalhes(obj) {
      this.showDetalhes = obj
    }
  },
};
</script>

<style scoped>
#leitura-materiais-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #35384D;
}
.mat-hover-visibility {
  visibility: hidden;
}
.div-but-finalizar {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
  align-items: flex-end;
}
.but-finalizar {
  width: 200px;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
@media print {
  .qrCode-printer {
    height: 10.4cm !important;
    width: 2.85cm !important;
    padding: 0.2cm !important;
    padding-top: 0.5cm !important;
  }

  .qrCode-div {
    margin-bottom: 2.3cm !important;
  }

  .qr-code {
    margin-left: 0.55cm !important;
    height: 2cm !important;
    width: 2.2cm !important;
  }

  .descricao-material {
    width: 100% !important;
    font-size: 0.3cm !important;
    height: 0.6cm !important;
    margin-top: 0cm !important;
    margin-left: 0.1cm !important;
    margin-bottom: 2.9cm !important;
  }

  .qrCode-INVCXCuuid {
    width: 100% !important;
    font-size: 0.26cm !important;
    height: 1.5cm !important;
    margin-left: 0.1cm !important;
  }

  .printOnly {
    display: inline-block !important;
  }
}
.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}
</style>
