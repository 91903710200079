import { render, staticRenderFns } from "./LeituraMateriais.vue?vue&type=template&id=795946af&scoped=true&"
import script from "./LeituraMateriais.vue?vue&type=script&lang=js&"
export * from "./LeituraMateriais.vue?vue&type=script&lang=js&"
import style0 from "./LeituraMateriais.vue?vue&type=style&index=0&id=795946af&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../front-end/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795946af",
  null
  
)

export default component.exports