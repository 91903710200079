<template>
  <b-row>
    <b-col sm="12" md="6" lg="4" xl="4">
      <b-form-group label="Sala*">
        <b-form-input
          id="sala"
          name="sala"
          :value="model.sala"
          class="invision-input"
          :disabled="isBusy"
          v-validate="'required'"
          :state="validateState('sala')"
          @input="v => updateModel('sala', v)"
        />
      </b-form-group>
    </b-col>
    <b-col sm="12" md="6" lg="4" xl="4">
      <b-form-group label="Aviso*">
        <b-form-input
          id="aviso"
          name="aviso"
          :value="model.aviso"
          class="invision-input"
          :disabled="isBusy"
          v-validate="'required'"
          :state="validateState('aviso')"
          @input="v => updateModel('aviso', v)"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'InformacoesSaidaPaciente',
  inject: [
    'parentValidator',
    'validateState',
    'updateModel',
  ],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
