<template>
  <div>
    <div v-if="currentPage == 2">
      <LeituraMateriais
        :isBusy="isBusy"
        :model="model"
        detalheMaterial
        hideMateriaisGenerico
        withoutTitle
      />
    </div>
  </div>
</template>

<script>
import LeituraMateriais from '../../Components/LeituraMateriais';

export default {
  name: 'RetornoArsenalBloco',
  components: {
    LeituraMateriais,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },
};
</script>
