<template>
  <div>
    <div class="customBanner" v-if="bannerType === 'preparo'">
      <h4>Informações</h4>
      <div class="info-custom" v-if="model.lavagem_automatizada">
        <h5>Limpeza aut.:</h5>
        <div class="div-info-sm">
          <span class="info-sm">Colaborador: {{model.colaborador_lavagem_automatizada}}</span>
        </div>
        <div class="div-info-sm">
          <span class="info-sm">Equipamento: {{model.nomeEquipamento}}</span>
        </div>
        <div class="div-info-sm">
          <span class="info-sm">Lote detergente: {{model.lote_detergente_lavagem_automatizada}}</span>
        </div>
      </div>
      <div class="info-custom" v-else>
        <span class="info-sm">Sem lavagem aut.</span>
      </div>
      <div class="info-custom">
        <h5>Qtd peças:</h5>
        <span class="info-sm">{{model.quantidade_pecas || 'Não contada'}}</span>
      </div>
      <div class="info-custom">
        <h5>Embalagem:</h5>
        <span class="info-sm">{{embalagem}}</span>
      </div>
      <div class="info-custom">
        <h5>Integrador Químico:</h5>
        <span class="info-sm">{{model.integrador_quimico ? 'Sim' : 'Não'}}</span>
      </div>
    </div>
    <div v-else-if="selectedMaterial.id_material_generico">
      <label>Tipo de material:</label>
      <br />
      <div class="info">{{ selectedMaterial.tipoMaterialGenerico.nome }}</div>

      <label>Descrição:</label>
      <br />
      <div class="info">{{ selectedMaterial.descricao }}</div>

      <label>Quantidade:</label>
      <div class="info">{{selectedMaterial.qt_material_generico}}</div>
      <br />
    </div>
    <div v-else>
      <label>Tipo de material:</label>
      <br />
      <div class="info">{{ selectedMaterial.id_material ? selectedMaterial.tipoMaterial.nome : '-' }}</div>

      <label>ID / Descrição:</label>
      <br />
      <div class="info">{{ idDescricao }}</div>

      <label>Peças cadastradas:</label>
      <div class="info">{{
        (selectedMaterial.id_material && selectedMaterial.quantidade_itens)
          ? selectedMaterial.quantidade_itens
          : '-'
      }}</div>
      <br />
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    selectedMaterial: {
      type: Object,
      default() {
        return {};
      },
    },
    model: {
      type: Object,
      default() {
        return {};
      },
    },
    bannerType: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      embalagem: function embalagem(state) {
        if (
          !state.genericData
          || !state.genericData.tipoEmbalagem
          || !this.model.id_tipo_embalagem
        ) return '';
        return state.genericData.tipoEmbalagem
          .find((emb) => emb.id_tipo_embalagem === this.model.id_tipo_embalagem)
          .nome;
      },
      idDescricao: function idDescricao() {
        if (!this.selectedMaterial.id_material) return '-'; // nao tem material selecionado
        return `${this.selectedMaterial.shownId} / ${this.selectedMaterial.descricao}`;
      },
    }),
  },
};
</script>

<style scoped>
* {
  font-weight: 400;
  font-size: 16px;
}

label {
  margin-bottom: 3px;
}

.info {
  margin-bottom: 12px;
}

.info-custom {
  margin-bottom: 18px;
  line-height: 14px;
}

.info-sm {
  font-weight: normal;
  font-size: 13px;
  color: #F2FFFD;
  line-height: 5px;
  margin-bottom: 2px;
}
.customBanner h4 {
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
  margin-bottom: 5px;
}
.customBanner h5 {
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  margin-bottom: 0px;
}
.div-info-sm {
  margin-bottom: 4px;
}
</style>
