export default {
  enableMaterialRede: true,
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  modelProcesso: {},
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Leitura dos materiais',
      subItems: [
        { title: 'Leitura dos materiais' },
      ],
    },
  ],
};
