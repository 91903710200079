<template>
  <div>
    <b-table
      :fields="tableFields"
      :items="model.materiais"
      class="mt-3"
      responsive
      show-empty
      empty-text="Não existem materiais selecionados"
    >
      <template #cell(state)="{ index }">
        <b-button
          class="btn btn-sm text--black"
          variant="outline-dark"
          @click="removeMaterial(index)"
        >
          <trash-2-icon class="w20px" />
        </b-button>
      </template>
      <template #cell(hasNaoConformidade)="{ index, value }">
        <div class="text-center">
          <alert-triangle-icon
            class="w20px pointer"
            :color="value ? '#209F85' : '#5E627A'"
            @click="$emit('go-to-integridade', index)"
          />
        </div>
      </template>
      <template #cell(repo)="{ item, index }">
        <edit-icon
          :class="editIconClass(item)"
          @click="handleEditBut(item, index)"
        />
      </template>
    </b-table>
    <b-row align-h="between" class="pt-4" v-if="!preventDefaultButtons">
      <b-col sm="3" md="2" lg="2" xl="2">
        <Button
          text="Cancelar"
          variant="grayscale"
          :loading="isBusy"
          @click="discardForm"
        />
      </b-col>
      <b-col sm="9" md="10" lg="10" xl="10" class="text-right">
        <Button
          text="Novo material"
          type="outline"
          :loading="isBusy"
          class="mr-4"
          @click="goToPage(materialDetectionPage || currentPage - 1)"
        />
        <Button
          :text="confirmButtonText"
          :loading="isBusy"
          @click="confirmPage"
        />
      </b-col>
    </b-row>
    <b-modal
      id="modalEditIntegridade"
      ref="modalEditIntegridade"
      title="Reposição"
      hide-footer
      size="lg"
      @hidden="closingEditModal"
    >
      <Reposicao
        :model="model"
        :editingMaterial="editingMaterial"
        :allDefeitos="allDefeitos"
        :isBusy="isBusy"
        @hide-modal="hideModal"
        @update-editing-material="updateEditingMaterial"
      />
    </b-modal>
  </div>
</template>
<script>
import GenericApi from '../../../../../front-end/src/services/genericRequest';
import Button from '../../../../../front-end/src/components/Utils/Button';
import Reposicao from './Reposicao';

export default {
  inject: ['removeMaterial', 'goToPage', 'discardForm', 'confirmPage', 'addMaterialToModel'],
  components: {
    Button,
    Reposicao,
  },
  data() {
    return {
      editingMaterial: {},
      allDefeitos: [],
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    materialDetectionPage: {
      type: Number,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    withNaoConformidade: {
      type: Boolean,
      default: false,
    },
    withReposicao: {
      type: Boolean,
      default: false,
    },
    withPertenceA: {
      type: Boolean,
      default: false,
    },
    withPecasRecebidas: {
      type: Boolean,
      default: false,
    },
    withPecasPreparadas: {
      type: Boolean,
      default: false,
    },
    preventDefaultButtons: {
      type: Boolean,
      default: false,
    },
    removeColumnPecasCadastradas: {
      type: Boolean,
      default: false,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    tableFields() {
      const fields = [
        {
          key: 'state',
          label: 'Remover',
          sortable: false,
        },
        {
          key: 'tipoMaterial.nome',
          label: 'Tipo de artigo',
          sortable: false,
        },
        {
          key: 'shownId',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'descricao',
          label: 'Descrição',
          sortable: false,
        },
      ];
      if (!this.removeColumnPecasCadastradas) {
        fields.push({
          key: 'quantidade_itens',
          label: 'Qde peças cadastradas',
          sortable: false,
        });
      }
      if (this.withPecasRecebidas) {
        fields.push({
          key: 'quantidade_pecas',
          label: 'Qde peças recebidas',
          sortable: false,
        });
      }
      if (this.withPecasPreparadas) {
        fields.push({
          key: 'quantidade_pecas',
          label: 'Qde peças preparadas',
          sortable: false,
        });
      }
      if (this.withPertenceA) {
        fields.push({
          key: 'pertenceA',
          label: 'Pentence a',
          sortable: false,
        });
      }
      if (this.withNaoConformidade) {
        fields.push({
          key: 'hasNaoConformidade',
          label: 'Registrar não conformidade',
          sortable: false,
        });
      }
      if (this.withReposicao) {
        fields.push({
          key: 'repo',
          label: 'Reposição',
          sortable: false,
        });
      }
      return fields;
    },
    confirmButtonText() {
      return this.numberOfPages === this.currentPage + 1 ? 'Finalizar' : 'Confirmar';
    },
  },
  mounted() {
    if (this.currentMaterialModel.id_material) {
      this.addMaterialToModel();
    }
  },
  methods: {
    hideModal() {
      this.$refs.modalEditIntegridade.hide();
    },
    updateEditingMaterial(prop, val) {
      this.editingMaterial[prop] = val;
    },
    handleEditBut(item, index) {
      this.editingMaterial = { ...item, index };
      this.showEditModal();
      if (
        this.editingMaterial.ids_defeitos
        && this.editingMaterial.ids_defeitos.length
        && !this.allDefeitos.length
      ) {
        this.getAllDefeitos();
      }
    },
    addDescricaoDefeitos() {
      this.editingMaterial.ids_defeitos.push(null);
    },
    editIconClass(item) {
      if (item.isEdited) {
        return 'w20px editButton editedButton';
      }
      return 'w20px editButton';
    },
    showEditModal() {
      this.$refs.modalEditIntegridade.show();
    },
    async handleConfirmModal() {
      if (this.editingMaterial.quantidade_pecas !== this.editingMaterial.quantidade_itens
        && this.editingMaterial.quantidade_pecas != null
        && this.editingMaterial.quantidade_pecas_faltantes_repostas
          <= this.editingMaterial.quantidade_itens - this.editingMaterial.quantidade_pecas
      ) {
        this.editingMaterial.quantidade_pecas = parseInt(this.editingMaterial.quantidade_pecas, 10)
          + parseInt(this.editingMaterial.quantidade_pecas_faltantes_repostas, 10);
      }
      const { index, ...newMaterialData } = this.editingMaterial;
      if (newMaterialData.quantidade_pecas === newMaterialData.quantidade_itens) {
        newMaterialData.justificativa = '';
      }

      const result = await this.$validator.validateAll();
      if (result) {
        this.$emit('update-model', 'materiais', [
          ...this.model.materiais.slice(0, index),
          { ...this.model.materiais[index], ...newMaterialData, isEdited: true },
          ...this.model.materiais.slice(index + 1),
        ]);
        this.$refs.modalEditIntegridade.hide();
      }
    },
    closingEditModal() {
      this.editingMaterial = {};
    },
    handleChangeQtdDefeituoso(value) {
      if (value < this.editingMaterial.ids_defeitos.length) {
        this.editingMaterial.ids_defeitos = value < 1
          ? [null]
          : this.editingMaterial.ids_defeitos.slice(0, value);
      }
    },
    async getAllDefeitos() {
      const defeitos = await GenericApi.getWithoutPagination({}, 'tipoDefeitoMaterial');
      this.allDefeitos = defeitos.reduce((Acum, defeito) => ({
        ...Acum,
        [defeito.id_tipo_defeito_material]: defeito.nome,
      }), {});
    },
  },
};
</script>
