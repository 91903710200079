<template>
  <div>
    <b-row class="input-p">
      <b-col sm="12" md="12" lg="12" xl="6">
        <b-form-group
          label="Tipo de Processamento*"
          label-for="tipoProcessamento"
        >
          <GenericSelect
            ref="selectTipoProcessamento"
            name="tipoProcessamento"
            labelKey="nome"
            :value="model.id_tipo_esterilizacao"
            route="tipoEsterilizacao"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('tipoProcessamento')"
            @input="handleChangeIdEsterilizacao"
            :customFilters="customFilters"
          ></GenericSelect>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="12" lg="12" xl="6">
        <b-form-group
          label="Lote do Equipamento da Esterilização*"
          label-for="lote"
          v-if="model.id_tipo_esterilizacao
            && model.tipoEsterilizacao !== 'desinfeccaoAlcool'"
        >
          <b-form-input
            id="lote"
            :value="model.lote"
            @input="val => updateModel('lote', val)"
            autocomplete="off"
            class="invision-input"
            name="lote"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('lote')"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="
      model.id_tipo_esterilizacao
        && model.tipoEsterilizacao !=='desinfeccaoAlcool'
    ">
      <b-col sm="12" md="12" lg="12" xl="6">
        <b-form-group
          label="Equipamento Esterilização*"
          label-for="equipamento"
        >
          <GenericSelect
            ref="selectEquipamento"
            name="equipamento"
            labelKey="nome"
            :value="model.id_equipamento"
            :customFilters="equipamentoEsterilizacaoFilters"
            route="equipamento"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('equipamento')"
            @input="(v) => updateModel('id_equipamento', v)"
          ></GenericSelect>
        </b-form-group>
      </b-col>
      <b-col
        sm="12" md="12" lg="12" xl="6"
        v-if="model.tipoEsterilizacao !=='termodesinfeccao'"
      >
        <b-form-group
          label="Tipo de Teste*"
          label-for="tipoTeste"
        >
          <GenericSelect
            ref="selectTipoTeste"
            name="tipoTeste"
            labelKey="nome"
            :value="model.id_tipo_teste"
            @input="val => updateModel('id_tipo_teste', val)"
            route="tipoTeste"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('tipoTeste')"
          ></GenericSelect>
        </b-form-group>
      </b-col>
      <b-col
        sm="12" md="12" lg="12" xl="6"
        v-else
      >
        <b-form-group
          label="Nome do Colaborador*"
          label-for="colaborador_termodesinfeccao"
        >
          <b-form-input
            id="colaborador_termodesinfeccao"
            :value="model.colaborador_termodesinfeccao"
            autocomplete="off"
            class="invision-input"
            name="colaborador_termodesinfeccao"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('colaborador_termodesinfeccao')"
            @input="val => updateModel('colaborador_termodesinfeccao', val)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12" md="12" lg="12" xl="12"
        v-if="model.id_tipo_esterilizacao"
      >
        <b-form-checkbox
          id="ciclo_urgencia"
          name="ciclo_urgencia"
          placeholder="Ciclo de Urgência"
          :checked="model.ciclo_urgencia"
          @input="val => updateModel('ciclo_urgencia', val)"
          :state="validateState('ciclo_urgencia')"
        >
          Ciclo de Urgência
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';

export default {
  inject: ['parentValidator', 'validateState', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    GenericSelect,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(['currentUnidade']),
    equipamentoEsterilizacaoFilters() {
      let idRetorno = 0;
      if (this.currentUnidade.id_unidade) {
        idRetorno = this.currentUnidade.id_unidade;
      }

      return {
        id_unidade: idRetorno,
        esterilizacao: true,
        id_tipo_esterilizacao: this.model.id_tipo_esterilizacao,
      };
    },
  },
  methods: {
    handleChangeIdEsterilizacao(val) {
      const esterilizacao = this.$refs.selectTipoProcessamento.apiResponse.find(
        (item) => item.id_tipo_esterilizacao === val,
      );
      this.updateModel(
        [
          'id_tipo_esterilizacao', 'tipoEsterilizacao', 'id_equipamento',
          'lote', 'colaborador_termodesinfeccao', 'id_tipo_teste',
        ],
        [val, (esterilizacao && esterilizacao.chave) || '', null, null, null, null],
      );
    },
  },
  data() {
    return {
      customFilters: {
        chave: 'vapor,peroxidoHidrogenio,desinfeccaoAlcool'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
form .input-p {
  padding-bottom: 24px; /* define o espaçamento entre os inputs */
}

form .input-p .form-group {
  margin-bottom: 0; /* tira margin de 16 px já existente no gupo correspondente ao primeiro input do formulario */
}
</style>
