<template>
  <div>
    <printer-icon
      class="w20px pointer align-middle"
      @click="print()"
      v-if="!isBusy"
    />
    <b-spinner v-else variant="secondary" small label="Spinning"/>

    <Modal
      ref="modalReport"
      no-close-on-esc
      no-close-on-backdrop
      :size="modalReportSize"
      centered
    >
      <DynamicReport
        :reportProcesso="dadosReimpressao.reportProcesso"
        :reportsMateriais="dadosReimpressao.reportsMateriais"
        :reportType='dadosReimpressao.reportType'
        @tipoVisualizacao="(input) => tipoVisualizacao = input"
      />
    </Modal>
  </div>
</template>

<script>
import GenericApi from '@/services/genericRequest';
import DynamicReport from '@/processos/report/DynamicReport';

import Modal from '@/components/Utils/Modal';

export default {
  name: 'GenericPrint',

  components: {
    DynamicReport,
    Modal,
  },

  props: {
    tipo: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    hasNoSelecetdMaterials: {
      type: Boolean,
      required: false,
    },
    hasReimpressaoDataByDefault: {
      type: Boolean,
      required: false,
    },
  },

  data: () => ({
    reportSizes: {
      etiqueta: 'md',
      relatorio: 'xl',
      default: 'lg',
    },

    isBusy: false,
    dadosReimpressao: {},
    tipoVisualizacao: null,
  }),

  computed: {
    modalReportSize() {
      if (this.dadosReimpressao
        && this.dadosReimpressao.reportType
        && this.dadosReimpressao.reportType.type
        && this.tipoVisualizacao) {
        return this.reportSizes[this.tipoVisualizacao] || 'lg';
      }

      return this.dadosReimpressao
        && this.dadosReimpressao.reportType
        && this.dadosReimpressao.reportType.type
        ? this.reportSizes[this.dadosReimpressao.reportType.type] : 'lg';
    },
  },

  methods: {
    async print() {
      if (this.hasNoSelecetdMaterials) {
        swal({
          icon: 'warning',
          title: 'Ops...',
          text: 'Você nao selecionou nenhum material para imprimir...',
          buttons: { confirm: 'Fechar' },
        });
        return;
      }

      try {
        this.isBusy = true;

        if (this.hasReimpressaoDataByDefault) {
          this.dadosReimpressao = {
            ...this.data.reimpressaoData,
          };
        } else {
          this.dadosReimpressao = await GenericApi.getWithoutPagination({
            filters: {
              data: JSON.stringify(this.data),
              tipo: this.tipo,
            },
          }, 'reimpressao');
        }

        if (this.tipo === 'processo'
            || this.tipo === 'material'
            || this.tipo === 'procedimento') {
          this.$refs.modalReport.show();
        }
        // CRIAR UM MODAL PARA CADA TIPO DE FORMATO DE REIMPRESSÃO
      } catch (error) {
        let errorMessage = 'Ocorreu um erro ao realizar a reimpressão';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        if (typeof error === 'string') errorMessage = error;

        swal({
          icon: 'error',
          title: 'Erro',
          text: errorMessage,
          buttons: { confirm: 'Fechar' },
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .pointer {
    cursor: pointer;
  }
</style>
