var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"input-p",attrs:{"sm":"12","md":"6","lg":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Unidade de origem*","label-for":"unidade_origem","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o nome da unidade de onde o material veio'
          ),expression:"\r\n            'Informe o nome da unidade de onde o material veio'\r\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('GenericSelect',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],ref:"selectUnidadeOrigem",attrs:{"name":"unidade_origem","labelKey":"nome","value":_vm.model.id_unidade_origem,"route":"unidade","disabled":_vm.isBusy || _vm.unidadeDisabled,"state":_vm.validateState('unidade_origem')},on:{"input":_vm.handleChangeUnidadeOrigem}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6","xl":"612"}},[_c('b-form-group',{attrs:{"label":"Setor de origem*","label-for":"setor_origem","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o nome do setor de onde o material veio'
          ),expression:"\r\n            'Informe o nome do setor de onde o material veio'\r\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('GenericSelect',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],ref:"selectSetorOrigem",attrs:{"name":"setor_origem","labelKey":"nome","value":_vm.model.id_setor_origem,"route":"setor","customFilters":_vm.setorSelectFilters,"disabled":_vm.isBusy || _vm.setorDisabled,"state":_vm.validateState('setor_origem')},on:{"input":function (v) { return _vm.updateModel('id_setor_origem', v); }}})],1)],1)],1),(_vm.needResponsavel)?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Identificação responsável setor","label-for":"responsavelSetor","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o nome do responsável pela realização da entrega dos materiais'
          ),expression:"\r\n            'Informe o nome do responsável pela realização da entrega dos materiais'\r\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:(Object.assign({}, {required: true}, 
            this.getStringValidationRules(_vm.config))),expression:"{ \r\n            required: true, \r\n            ...this.getStringValidationRules(config)}"}],staticClass:"invision-input input-icon",attrs:{"name":"responsavelSetor","value":_vm.model.responsavel_setor,"autocomplete":"off","disabled":_vm.isBusy,"state":_vm.validateState('responsavelSetor')},on:{"input":function (v) { return _vm.updateModel('responsavel_setor', v); },"blur":function () { return this$1.config && this$1.config.validLengthInputText && _vm.validateInputText('responsavelSetor'); }}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }