<template>
  <div>
    <b-row>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group
          label="Unidade de destino"
          label-for="unidade_destino"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o nome da unidade de destino do material'
            "
            class="tooltip-icon"
          />
          <GenericSelect
            ref="selectUnidadeDestino"
            name="id_unidade_destino_procedimento"
            labelKey="nome"
            :value="
              currentMaterialModel.procedimento
              && currentMaterialModel.procedimento.id_unidade_destino
            "
            route="unidade"
            :disabled="isBusy"
            v-validate="{ required: !allOptional }"
            :state="validateState('id_unidade_destino_procedimento')"
            @input="v => handleChangeProcedimento('id_unidade_destino', v)"
          ></GenericSelect>
        </b-form-group>

        <b-form-group
          label="Data do procedimento"
          label-for="data_procedimento"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe a data em que ocorrerá o procedimento'
            "
            class="tooltip-icon"
          />
          <b-form-datepicker
            id="data_procedimento"
            class="invision-input"
            placeholder="Data"
            :value="
              currentMaterialModel.procedimento
              && currentMaterialModel.procedimento.data_procedimento
            "
            @input="v => handleChangeProcedimento('data_procedimento', v)"
            name="data_procedimento"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            locale="pt"
            :disabled="isBusy"
            v-validate="{ required: !allOptional }"
            :state="validateState('data_procedimento')"
          >
          </b-form-datepicker>
        </b-form-group>

        <b-form-group
          v-if="currentMaterialModel.id_tipo_material === 4"
          label="Aviso*"
          label-for="aviso"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o aviso'
            "
            class="tooltip-icon"
          />
          <b-form-input
            id="aviso_procedimento"
            :value="
              currentMaterialModel.procedimento
              && currentMaterialModel.procedimento.aviso
            "
            autocomplete="off"
            class="invision-input"
            name="aviso_procedimento"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('aviso_procedimento')"
            @input="v => handleChangeProcedimento('aviso', v)"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group
          v-if="currentMaterialModel.id_tipo_material === 4"
          label="Paciente"
          label-for="paciente"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o nome do paciente'
            "
            class="tooltip-icon"
          />
          <b-form-input
            id="paciente"
            :value="
              currentMaterialModel.procedimento
              && currentMaterialModel.procedimento.paciente"
            autocomplete="off"
            class="invision-input"
            name="paciente"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('paciente')"
            @input="v => handleChangeProcedimento('paciente', v)"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-else
          label="Número de peças*"
          label-for="numero_pecas"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Se o valor for alterado, será modificado o cadastro da quantidade de peças do material'
            "
            class="tooltip-icon"
          />
          <b-form-input
            id="numero_pecas"
            type="number"
            :value="currentMaterialModel.quantidade_itens"
            autocomplete="off"
            class="invision-input"
            name="numero_pecas"
            min="1"
            :disabled="isBusy"
            v-validate="{ required: true, min_value: 1}"
            :state="validateState('numero_pecas')"
            @input="handleChangeQuantidadeItens"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Horário do procedimento"
          label-for="horario_procedimento"
        >
          <HourInput
            name="hour_input"
            :isBusy="isBusy"
            @input="v => handleChangeProcedimento('horario_procedimento', v)"
            :value="
              currentMaterialModel.procedimento
              && currentMaterialModel.procedimento.horario_procedimento"
            v-validate="{ required: !allOptional }"
            :state="validateState('hour_input')"
          />
        </b-form-group>

        <b-form-group
          v-if="currentMaterialModel.id_tipo_material === 4"
          label="Protocolo*"
          label-for="protocolo"
          label-class="float-left"
        >
          <info-icon
              v-b-tooltip.hover.right="
                'Informe o protocolo'
              "
              class="tooltip-icon"
            />
          <b-form-input
            id="protocolo"
            :value="
              currentMaterialModel.procedimento
              && currentMaterialModel.procedimento.protocolo"
            autocomplete="off"
            class="invision-input"
            name="protocolo"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('protocolo')"
            @input="v => handleChangeProcedimento('protocolo', v)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col v-if="canTakeProcedimento" sm="12" md="6" lg="6" xl="6">
        <Button
          text="Copiar último procedimento"
          size="lg"
          variant="primary-light"
          @click="handleCopyLastProcedimento"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';
import HourInput from '../../../../../front-end/src/components/Utils/Hour';
import Button from '../../../../../front-end/src/components/Utils/Button';

export default {
  inject: ['parentValidator', 'validateState', 'updateCurrentMaterialModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    GenericSelect,
    HourInput,
    Button,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    validateState: {
      type: Function,
      required: true,
    },
  },
  computed: {
    allOptional() {
      // pra médico tudo é opcional
      // Parametrizado, no lugar if(this.currentMaterialModel.id_tipo_material === 2)
      if (this.currentMaterialModel.tipoMaterial && this.currentMaterialModel.tipoMaterial.gera_procedimento) return true;
      return false;
    },
    canTakeProcedimento() {
      if (this.currentMaterialModel.procedimento && this.currentMaterialModel.procedimento.id_procedimento) {
        return false;
      }
      if (
        this.model.materiais[this.model.materiais.length - 1]
        && this.model.materiais[this.model.materiais.length - 1].procedimento
      ) return true;
      return false;
    },
  },
  mounted() {
    this.updateCurrentMaterialModel('quantidade_itens', 0);
  },
  methods: {
    handleChangeProcedimento(prop, value) {
      this.updateCurrentMaterialModel(
        'procedimento',
        { ...this.currentMaterialModel.procedimento, [prop]: value },
      );
    },
    handleChangeQuantidadeItens(qtdItens) {
      this.updateCurrentMaterialModel('quantidade_itens', qtdItens);
      this.updateCurrentMaterialModel('quantidade_pecas', qtdItens);
      this.updateCurrentMaterialModel('justificativa', '');
    },
    handleCopyLastProcedimento() {
      const newProcedimento = { ...this.model.materiais[this.model.materiais.length - 1].procedimento };
      if (newProcedimento.id_procedimento) {
        delete newProcedimento.id_procedimento;
        delete newProcedimento.createdAt;
        delete newProcedimento.updatedAt;
        delete newProcedimento.deletedAt;
      }
      this.updateCurrentMaterialModel('procedimento', newProcedimento);
    },
  },
};
</script>
