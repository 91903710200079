<template>
  <main class="container padding">
    <section id="printSection">
      <b-row>
        <b-col cols=12>
          <div class="h-100">
            <label class="label-titulo">
              <h3>{{ reportType.processo.slice(0, 26) }}</h3>
              <h3 v-if="reportType.processo.length > 25">{{ reportType.processo.slice(26) }}</h3>
              <b-row v-if="reportProcesso.protocolo">
                <b-col>
                  <barcode
                    :value="`${reportProcesso.protocolo.sigla}${reportProcesso.protocolo.numero}`"
                    width="1"
                    height="20"
                    fontSize="12"
                  />
                </b-col>
              </b-row>
            </label>
            <img
              :src="imgLogo()"
              class="float-right"
              alt="logo"
              width="200px" />
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-3 pb-3 mb-3">
        <b-col class="mt-4" sm="4" cols="3"
          v-for="(value, key) in reportProcesso.fields"
          :key="key"
        >
          <span>
            <b>{{ key }}:</b> {{ value }}
          </span>
        </b-col>
      </b-row>

      <b-row
        v-for="(protocolo) in protocolos"
        :key="`protocolo${protocolo}`"
        class="mb-4"
      >
        <b-col cols="12">
          <b-table
            class="mt-0"
            :items="materiaisFromProtocolo(protocolo).map((r) => r.fields)"
          />
        </b-col>
        <b-col
          cols="12"
          v-if="protocolo"
          class="text-center"
          align-v="center"
          style="justify-content: center;"
        >
          <barcode
            :value="protocolo"
            width="1"
            height="20"
            fontSize="12"
          />
          <b
            style="font-size: 12px;"
            v-if="(reportsMateriais.filter((m) => m.protocolo === protocolo)).length
            && (reportsMateriais.filter((m) => m.protocolo === protocolo))[0].dataHoraProtocolo"
          >
            {{ (reportsMateriais.filter((m) => m.protocolo === protocolo))[0].dataHoraProtocolo }}
          </b>
        </b-col>
        <hr>
      </b-row>
    </section>
  </main>
</template>

<script>
import VueBarcode from 'vue-barcode';

export default {
  name: 'Report',

  components: {
    barcode: VueBarcode,
  },

  props: {
    reportProcesso: {
      type: Object,
      required: true,
    },
    reportsMateriais: {
      type: Array,
      required: true,
    },
    reportType: {
      type: Object,
      required: true,
    },
  },

  computed: {
    protocolos() {
      return this.reportsMateriais.reduce((acum, report) => {
        if (acum.includes(report.protocolo)) return acum;

        return [...acum, report.protocolo];
      }, []);
    },
  },

  methods: {
    // TODO Buscar logo do back end e armazenar em cache
    imgLogo() {
      try {
        // eslint-disable-next-line global-require, import/no-dynamic-require
        return require(`../../../../projects/${process.env.VUE_APP_PROJECT}/front-end/assets/img/logo.png`);
      } catch (e) {
        // eslint-disable-next-line global-require
        const logo = require('@/assets/img/logo.png');
        return logo;
      }
    },

    materiaisFromProtocolo(protocolo) {
      return this.reportsMateriais.filter((r) => r.protocolo === protocolo);
    },
  },
};
</script>

<style scoped>
  .label-titulo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .label-protocolo {
    font-size: 1.125rem;
    margin-bottom: 0;
  }
</style>
