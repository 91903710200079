<template>
  <b-container id="full-wrapper">
    <div v-if="isBusy" class="spinner-tabela-materiais-protocolos">
      <b-spinner class="spinner" />
    </div>
    <b-container id="table-wrapper">
      <b-row>
        <b-col>
          <div
            class="table-protocolos"
            :class="isBusy ? 'loading-tabela-protocolos' : ''"
            v-for="protocolo in materiaisByProtocolos"
            :key="protocolo.numero || '-'"
          >
            <div class="table-header">
              <b-row>
                <b-col cols="10">
                  <span v-if="protocolo.numero">{{ typeProt }} </span>
                  <span>{{ protocolo.numero || 'Materiais de rede'}} </span>
                  <span v-if="protocolo.numero">({{ protocolo.setorOrigem }} {{ protocolo.unidadeOrigem }})</span>
                </b-col>
              </b-row>
            </div>
            <div class="table-body">
              <b-row class="table-keys">
                <b-col md="1">
                  <b-form-checkbox
                    :indeterminate="indeterminated"
                    :checked="allChecked"
                    @change="v => handleCheckAllBoxes(v)"
                  />
                  <file-plus-icon
                    id="tkeys-icon"
                    v-if="haveBoxesChecked && !isDeletionAvailable"
                    @click="addManyMaterialsToModel"
                  />
                  <file-minus-icon
                    id="tkeys-icon"
                    v-else-if="isDeletionAvailable"
                    @click="removeSelectedMateriais"
                  />

                </b-col>
                <b-col v-for="(col, index) in columns" :key="col + index" :md="columnSize">
                  <span>
                    {{ col }}
                  </span>
                </b-col>
              </b-row>
              <b-row
                class="table-row"
                v-for="materialProt in protocolo.materiais"
                :class="[materialProt.read && 'current-read', selected.includes(materialProt) ? 'row-selected' : '']"
                :key="materialProt.id_material"
                @mouseleave="mouseLeave"
              >
                <b-col md="1">
                  <b-form-checkbox-group
                    name="checkboxes"
                    v-model="selected"
                  >
                    <b-form-checkbox
                      :name="materialProt.descricao"
                      :value="materialProt"
                      ref="checkboxes"
                      :disabled="materialProt.check && materialProt.check.msg"
                    />
                  </b-form-checkbox-group>
                </b-col>
                <b-col
                  v-for="(key) in columnsKeys"
                  :key="key"
                  :class="materialProt.read ? 'read-material' : 'not-read-material'"
                  :md="columnSize"
                >
                  <span>
                    {{ materialProt[key] || '-' }}
                  </span>
                  <b-tooltip v-if="materialProt.check && materialProt.check.msg"
                    :target="String(materialProt.id_material || materialProtid_material_generico)" triggers="hover"
                    placement="left">
                    {{materialProt.check.msg}}
                  </b-tooltip>                  
                </b-col>
                  <b-col md="2">
                  <b-col>
                    <span
                      class= "status"
                      :id="materialProt.id_material || materialProt.id_material_generico"
                      v-if="materialProt.check && materialProt.check.msg && enableMaterialStatus"
                    >Indisponível</span>
                  </b-col>
                  <b-col
                    v-if="enableOptions && materialProt.read && materialProt.id_material_generico">
                    <more-horizontal-icon
                      @click="handleOpenOptionsMenu(materialProt)"
                      class="h4 three-dots icons-color"
                    />
                  </b-col>
                </b-col>
                <div id="item-menu" v-if="openMenuFor === (materialProt.readId)">
                  <span
                    v-if="enableEditQtdRecebido"
                    class="item-menu-option"
                    @click="handleEditQtdRecebido(materialProt.readId)"
                  >
                    Alterar quantidade de materiais recebidos
                  </span>
                </div>
                <div id="edit-qtd-input" v-if="isEditingFor === materialProt.readId">
                  <b-form-group
                    label="Quantidade"
                    label-for="quantidade_material"
                  >
                    <b-form-input 
                      id="quantidade_material"
                      name="quantidade_material"
                      type="number"
                      :value="materialProt.qt_material_generico"
                      @input="(value) => handleChangeField('qt_material_generico', +value)"
                      v-validate="`between:1,${materialProt.qt_material_generico}`"
                      :state="validateState('quantidade_material')"
                    />
                  </b-form-group>
                  <b-button class="confirmar-edit-button" @click="handleConfirmEditField('quantidade_material', 'qt_material_generico', materialProt.readId)">Confirmar</b-button>
                </div>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: 'TabelaMateriaisTransferencia',
  inject: ['setCurrentMaterialModel', 'addMaterialToModel', 'removeManyMateriais', 'setCurrentMaterialFromModel', 'parentValidator', 'validateState', 'updateCurrentMaterialModel', 'setFeedbackNotification', 'updateModel'],
  props: {
    enableOptions: {
      type: Boolean,
      required: false,
      default: true
    },
    enableMaterialStatus: {
      type: Boolean,
      required: false,
      default: true
    },
    columns: {
      type: Array,
      required: true
    },
    columnsKeys: {
      type: Array,
      required: true
    },
    isBusy: {
      type: Boolean,
      required: true
    },
    materiaisByProtocolos: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: false,
    },
    enableEditQtdRecebido: {
      type: Boolean,
      required: false,
      default: () => true
    },
    typeProt: {
      type: String,
      required: true,
      default: 'Protocolo'
    }
  },
  data() {
    return {
      openMenuFor: null,
      selected: [],
      currentIndexes: [],
      allMateriaisJoined: [],
      isEditingFor: null,
    }
  },
  created() {
    this.$validator = this.parentValidator
  },
  computed: {
    isDeletionAvailable() {
      if(this.selected.length) {
        return this.selected.every(selectedMaterial =>
          this.model.materiais.some(modelMaterial => selectedMaterial.readId === modelMaterial.readId)
        )
      } else {
        return false
      }
    },
    onlyValidMateriais() {
      return this.allMateriaisJoined.filter(material => material.check.disponivel)
    },
    haveBoxesChecked() {
      return Boolean(this.selected.length);
    },
    allChecked() {
      return this.selected.length && this.selected.length === this.onlyValidMateriais.length
    },
    indeterminated() {
      if (this.allChecked) return false;
      return this.haveBoxesChecked;
    },
    columnSize() {
      const bootstrapGrid = 12;
      const iconSlots = 2;
      const remainingSlots = bootstrapGrid - iconSlots;
      const value = Math.floor(remainingSlots / this.columns.length);

      return value;
    },
  },
  methods: {
    async handleConfirmEditField(validationField, field, readId) {
      if(!this.veeFields[validationField]) return
      if(!this.veeFields[validationField].valid) return

      const { materiais } = this.model
      const material = materiais.find(material => material.readId === readId)
      const indexOfMaterial = materiais.indexOf(material)

      const temp = [
        ...materiais.slice(0, indexOfMaterial),
        ...materiais.slice(indexOfMaterial + 1)
      ] 
      material[field] = this.currentMaterialModel[field]

      this.updateModel('materiais', temp)
      new Promise((resolve) => {
        this.addMaterialToModel()
        resolve()
      })
    },
    handleChangeField(field, value) {
      this.updateCurrentMaterialModel(field, value)
    },
    addManyMaterialsToModel() {
      let materialAlreadyInModel

      this.selected.forEach(selectedMaterial => {
        materialAlreadyInModel = this.model.materiais.some(materialModel => 
        selectedMaterial.readId === materialModel.readId)

        const materialToAdd = selectedMaterial.materialData ?
        {...selectedMaterial.materialData } :
        selectedMaterial

        if(!materialAlreadyInModel) {
          // Timeout necessário para garantir a execução
          // para cada um dos materiais
          setTimeout(() => {
            this.setCurrentMaterialModel(
              materialToAdd
            )
            this.addMaterialToModel()
          }, 0)
        }
        // Timeout para executar após a adição dos materiais e conseguir ver
        // o valor atual
        setTimeout(() => {
          this.setFeedbackNotification({
            type: 'success', 
            message: 'Materiais adicionados a seleção com sucesso. Clique em finalizar se deseja confirmar a liberação.'
          })
        }, 0)
      })
      this.selected = []
    },
    handleEditQtdRecebido(readId) {
      this.isEditingFor = readId
      const materialIndexToEdit = this.model.materiais.findIndex(
        (material) => material.readId === readId,
      );
      if(materialIndexToEdit || materialIndexToEdit === 0) {
        this.setCurrentMaterialFromModel(materialIndexToEdit);
      }
      this.openMenuFor = null;
    },
    async removeSelectedMateriais() {
      const ids = this.selected.map(material => material.readId)
      const removed = await this.removeManyMateriais(ids);
      if (removed) {
        this.setFeedbackNotification({
          type: 'alert',
          message: 'Os materiais foram removidos da seleção.'
        })
        this.selected = [];
      }
    },
    handleCheckAllBoxes(v) {
      const { checkboxes } = this.$refs;
      const enabledBoxes = checkboxes.filter((checkbox) => !checkbox.disabled);

      const selected = [];
      if (v) {
        enabledBoxes.forEach((box) => {
          if (!enabledBoxes.includes(box.value)) {
            selected.push(box.value);
          }
        });
      }
      this.selected = selected;
    },
    handleOpenOptionsMenu(materialProt) {
      if(!this.model.materiais.some(material => (
        (material.id_material && material.id_material === materialProt.id_material)
        || (material.id_material_generico && material.id_material_generico === materialProt.id_material_generico)
      ))) return;

      if (this.openMenuFor !== (materialProt.readId)) {
        this.openMenuFor = materialProt.readId;
        return;
      }
      this.openMenuFor = null;
    },
    mouseLeave() {
      this.openMenuFor = null;
      this.isEditingFor = null;
      if(this.currentMaterialModel) {
        this.setCurrentMaterialModel({})
      }
    },
  },
  watch: {
    materiaisByProtocolos: {
      immediate: true,
      deep: true,
      handler(materiaisByProtocolos) {
        if(materiaisByProtocolos && materiaisByProtocolos.length) {
          this.allMateriaisJoined = materiaisByProtocolos.reduce((prev, curr) => {
            return [...prev, ...curr.materiais]
          }, [])
        }
      }
    }
  }
}
</script>

<style scoped>
.confirmar-edit-button {
  background: #21CCA9 !important;
}
#edit-qtd-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  right: 0;
  background: #FFF;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
}
.row-selected {
  background:#C2F2EA !important;
}
.table-protocolos {
  padding: 24px;
  border: 1px solid #F0F1FC;
  border-radius: 10px;
  border-collapse: collapse;
}
.table-body {
  border: 1px solid #F0F1FC;
  padding: 15px 15px 0px 15px;
  border-radius: 10px;
}
.table-header {
  margin-bottom: 21px;
  color: #35384D;
}
.table-keys{
  padding-bottom: 15px;
  border-bottom: 1px solid #F0F1FC;
  color: #5E627A;
}
.table-row {
  padding: 15px 0 15px 0;
  color:#35384D;
  position: relative;
  background: #FFFFFF;
}
.table-row:nth-child(odd) {
  border-top: 1px solid #F0F1FC;
  border-bottom: 1px solid #F0F1FC;
}
.table-row:hover {
  background: #f7f7fa;
}
.table-row:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.invisible {
  opacity: 0;
}
.visible {
  opacity: 1
}
.three-dots:hover {
  cursor: pointer;
  color: #00a885;
}
.status {
  position: absolute;
  right: 10px;
  font-size: 12px;
  background: #e9e7f5;
  color: #7E829F;
  padding: 6px 9px 6px 9px;
  border-radius: 5px;
}
#material-dectection {
  margin-bottom: 50px;
}
#item-menu {
  position: absolute;
  right: 20px;
  top: 40px;
  z-index: 2;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid rgb(238, 238, 238);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
}
.item-menu-option {
  display: block;
  font-size: 0.9rem;
  padding: 15px;
}
.item-menu-option:hover {
  background: #f7f7fa;
  cursor: pointer;
}
.not-read-material {
  color: #999ba5;
}
.read-material {
  color: #35384d;
}
#tkeys-icon {
  position: absolute;
  top: -1px;
  right: -4px;
  width: 1.3rem;
  color: #7e829f;
  cursor: pointer;
}
.current-read{
  background:#f5f7ff;
}

.w-52 {
  width: 95%;
}
.loading-tabela-protocolos {
  opacity: 0.3;
}
.spinner-tabela-materiais-protocolos {
  position: absolute;
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9;
}
.spinner {
  color: #209f85;
  width: 100px;
  height: 100px;
}
</style>