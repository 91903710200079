<template>
  <div>
    <div v-if="currentPage === 2">
      <b-row>
        <div>
          Solicite um documento de identificação com foto do responsável pela
          retirada de materiais OPME e preencha os campos a seguir.
        </div>
        <b-col>
          <b-form-group label="Nome*">
            <b-form-input
              id="inputNomeRetiradaMaterialOPMEConsignado"
              name="nomeRetiradaMaterialOPMEConsignado"
              class="invision-input"
              :value="model.nomeRetiradaMaterialOPMEConsignado"
              autocomplete="off"
              v-validate="{ required: true }"
              :state="validateState('nomeRetiradaMaterialOPMEConsignado')"
              @input="(v) => updateModel('nomeRetiradaMaterialOPMEConsignado', v)"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-row>
          <b-col>
            <b-form-group label="RG*">
              <b-form-input
                id="inputRG"
                name="rgRetiradaMaterialOPMEConsignado"
                class="invision-input"
                :value="model.rgRetiradaMaterialOPMEConsignado"
                autocomplete="off"
                v-validate="{ required: true }"
                :state="validateState('rgRetiradaMaterialOPMEConsignado')"
                @input="(v) => updateModel('rgRetiradaMaterialOPMEConsignado', v)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>
    </div>
    <LeituraMateriais
      :isBusy="isBusy"
      :model="model"
      hideMateriaisGenerico
    />
  </div>
</template>

<script>
import MaterialDetectionPage from '../../Components/MaterialDetectionPage';
import LeituraMateriais from '../../Components/LeituraMateriais';

export default {
  name: 'DevolucaoOpmeConsignado',
  components: {
    MaterialDetectionPage,
    LeituraMateriais,
  },
  inject: ['parentValidator', 'validateState', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },
};
</script>
