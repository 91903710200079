<template>
  <div
    class="
      info-ciclo-card
      card
      invision-card
      borderless
      p-card
      div-container
      p-4
    "
    v-if="currentCiclo && currentCiclo.id_ciclo"
  >
    <b-container>
      <b-row align-h="between" align-v="center" class="">
        <b-col cols="8">
          <h5 class="d-flex">Ciclo do Material &nbsp;</h5>
        </b-col>
        <b-col cols="4">
          <router-link
            class="float-right"
            :to="route"
            title="Ir para edição do material"
          >
            <span class="span-edit-link">Ir para edição do material</span>
          </router-link>
        </b-col>
      </b-row>

      <b-row class="status-row pt-2">
        <b-col cols="2" class="label-status-row-style">N° do ciclo</b-col>
        <b-col
          v-if="material.limite_ciclos"
          cols="3"
          class="label-status-row-style"
          >Limite de ciclos de esterilização</b-col
        >
        <b-col cols="3" class="label-status-row-style">Status</b-col>
        <b-col
          v-for="(protocolo, index) in protocolos"
          :key="`tipoProtocolo${index}`"
          cols="2"
          class="label-status-row-style"
        >
          {{ protocolo.tipo.toUpperCase() }}
        </b-col>
        <b-col
          :cols="7 - (material.limite_ciclos ? 3 : 0) - 2 * protocolos.length"
          class="label-status-row-style"
        />

        <b-col cols="2" class="font-style-medium">
          {{ numberOfCiclos - currentOrder + 1 }}
        </b-col>
        <b-col v-if="material.limite_ciclos" cols="3" class="font-style-medium">
          {{ material.limite_ciclos }}
        </b-col>
        <b-col cols="3">
          <div
            class="status-tag d-flex justify-content-center"
            v-if="
              !currentCiclo.id_lote_saida &&
              currentCiclo &&
              currentCiclo.id_ciclo
            "
          >
            {{
              currentCiclo.processoCiclo[currentCiclo.processoCiclo.length - 1]
                .subSetor.nome
            }}
          </div>
          <div
            class="status-tag-warning d-flex justify-content-center"
            v-else-if="currentCiclo.processoCiclo.length"
          >
            {{
              currentCiclo.loteSaida && currentCiclo.loteSaida.id_usuario_forced
                ? 'Forçado'
                : 'Finalizado'
            }}
          </div>
        </b-col>

        <b-col
          v-for="(protocolo, index) in protocolos"
          :key="`viewProtocolo${index}`"
          cols="2"
          class="font-style-medium pointer"
          @click="viewProtocolo(protocolo)"
        >
          {{ protocolo.codigo }}
        </b-col>
        <b-col :cols="7 - 2 * protocolos.length" class="font-style-medium" />
      </b-row>
    </b-container>
    <div class="div-arrow-left" v-b-tooltip.hover.top="'Ciclo anterior'">
      <div
        class="arrows left-button ripple"
        font-scale="1.9"
        type="button"
        @click="changeCiclo(1)"
        v-if="currentOrder < numberOfCiclos"
      >
        <chevron-left-icon class="chevron-icon" />
      </div>
    </div>
    <div class="div-arrow-right" v-b-tooltip.hover.top="'Próximo ciclo'">
      <div
        class="arrows right-button ripple"
        font-scale="1.9"
        type="button"
        @click="changeCiclo(-1)"
        v-if="currentOrder > 1"
      >
        <chevron-right-icon class="chevron-icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentCiclo: {
      type: Object,
    },
    material: {
      type: Object,
    },
    currentOrder: {
      type: Number,
      default: 1,
    },
    numberOfCiclos: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      parametrosCicloDestino: null,
      route: {
        name: '',
        params: { id: this.$router.history.current.params.id },
      },
    };
  },

  computed: {
    protocolos() {
      return this.currentCiclo.materialProtocolo.map((p) => ({
        id_protocolo: p.protocolo.id_protocolo,
        id_tipo_protocolo: p.protocolo.id_tipo_protocolo,
        finalizado: p.finalizado,
        codigo: `${p.protocolo.sigla || ''}${p.protocolo.numero}`,
        tipo: p.protocolo.tipo.descricao,
      }));
    },
  },

  mounted() {
    if (this.material.id) this.route.name = 'materialEditar';
    else if (this.material.id_medico) this.route.name = 'materialMedicoEditar';
    else this.route.name = 'materialTemporarioEditar';
  },

  methods: {
    endCiclo() {
      this.$emit('end-ciclo');
    },

    changeCiclo(incrementInOrder) {
      this.$emit('change-ciclo', incrementInOrder);
    },

    viewProtocolo(protocolo) {
      const routeData = this.$router.resolve(
        `/consultaProtocolo/visualizar/${protocolo.codigo}/${protocolo.id_tipo_protocolo}`,
      );
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
$neutral-color-80: #35384d;
$neutral-color-70: #5e627a;

.span-edit-link {
  color: $neutral-color-70;
  outline: none;
}

.arrows {
  transition: 0.3s;
  border-radius: 50%;
  padding: 4px;
}

.arrows:hover {
  background-color: rgb(230, 230, 230);
}

.arrows:active {
  background-color: #8f8f8f;
}
.container-arrows {
  width: 50px;
}

.left-button {
  flex: 1;
}

.right-button {
  flex: 1;
  float: right;
}

.encerramento {
  flex: 1;
  margin-top: 10px;
  position: relative;
  right: 0px;
  //justify-content: center;
  text-align: center;
}

.status-encerramento {
  padding-top: 20px;
  text-align: center;
  font-weight: bold;
}

.div-container {
  position: relative;
}
.div-arrow-left {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  left: 0px;
  filter: drop-shadow(4px 4px 20px rgba(61, 70, 85, 0.15));
}

.div-arrow-right {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  right: -32px;
  filter: drop-shadow(4px 4px 20px rgba(61, 70, 85, 0.15));
}

.div-arrow-left .arrows {
  background: #ffffff;
  opacity: 0.8;
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-right .arrows {
  background: #ffffff;
  opacity: 0.8;
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-left .arrows:hover {
  background-color: rgb(230, 230, 230);
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-right .arrows:hover {
  background-color: rgb(230, 230, 230);
  background-position: center;
  transition: background 0.8s;
}

.ripple:active {
  background-color: rgb(128, 128, 128);
  background-size: 100%;
  transition: background 0s;
}

.info-ciclo-card .invision-btn-light {
  /* position: absolute;
  right: 36px; */
  transform: translate(0, -15%);
}

.status-row {
  padding-top: 20px;
}

.status-tag {
  background-color: rgba(46, 155, 229, 0.3);
  color: #0093e5;
  font-weight: 500;
  border-radius: 5px;
  padding: 2px;
  margin-top: 2px;
  text-align: center;
}

.status-tag-warning {
  background-color: rgba(228, 13, 31, 0.719);
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  width: 80%;
  padding: 2px;
  margin-top: 2px;
}

.label-status-row-style {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Neutral / neutral-color-60 */

  color: #7e829f;
}

.font-style-medium {
  font-weight: 600;
  margin-top: 4px;
}

.printOnly {
  display: none;
}

.card.invision-card.invision-top {
  border-top: 0 !important;
}

h5 {
  // titulo cabeçalho modal
  font-size: 18px;
  color: $neutral-color-80;
}

button {
  margin: auto;
}

.w-52 {
  width: 95%;
}

.tabela {
  background-color: white;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
