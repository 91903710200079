<template>
  <main class="container padding">
    <div class="header">
      <label class="title">Ciclos do material</label>
      <img src="/logo.png" alt="" class="modal-logo" width="150px" />
    </div>
    <div class="w">
      <b-row>
        <b-col>
          <h2>
            {{ cicloData.header ? cicloData.header.descricao : '-' }},
            {{
              cicloData.header && cicloData.header.id
                ? `ID: ${cicloData.header.id}`
                : '-'
            }}
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="info">
            Tipo de material:
            {{ cicloData.header ? cicloData.header.tipoMaterial : '-' }}
          </div>

          <div class="info">
            Número de ciclos:
            {{ cicloData.header ? cicloData.header.nCiclos : '-' }}
          </div>
        </b-col>

        <b-col>
          <div class="info">
            Unidade: {{ cicloData.header ? cicloData.header.unidade : '-' }}
          </div>

          <div class="info">
            Setor: {{ cicloData.header ? cicloData.header.setor : '-' }}
          </div>
        </b-col>
      </b-row>
    </div>
    <hr />
    <div v-for="(item, index) in cicloData.body" v-bind:key="index">
      <div class="header-ciclo">
        <span class="info"> Ciclo: {{ item.id_ciclo }}</span>
        <br /><br />
        <span class="info"> Status: {{ item.status }}</span>
        <br />
        <span class="info">
          Número de transferência: {{ item.nTransferencia }}</span
        >
        <br />
        <span class="info"> Voucher: {{ item.nVoucher }}</span>
        <br /><br />
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" style="font-size: 14px">#</th>
            <th scope="col" style="font-size: 14px">Unidade</th>
            <th scope="col" style="font-size: 14px">Processo</th>
            <th scope="col" style="font-size: 14px">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(elem, idx) in item.processoCiclo" v-bind:key="idx">
            <th scope="row">{{ idx + 1 }}</th>
            <td>{{ elem.unidade || '-' }}</td>
            <td>{{ elem.processo || '-' }}</td>
            <td>{{ elem.data || '-' }}</td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>
  </main>
</template>

<script>
export default {
  components: {},

  props: {
    cicloData: {
      type: Object,
    },
  },
  methods: {},
};
</script>

<style scoped>
* {
  font-weight: 400;
  font-size: 16px;
}

.padding {
  padding: 36px;
  font-weight: 400;
}

label {
  margin-bottom: 3px;
}

.info {
  margin-bottom: 12px;
}

.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}

.div-assinaturas {
  display: flex;
  margin-bottom: 10px;
}

.w h2 {
  font-size: 16px;
  font-weight: 600;
}

.modal-header {
  border-bottom: 0;
}

.header .modal-logo {
  position: absolute;
  right: 20px;
  top: 20px;
  margin-top: 10px;
}

.header .title {
  font-weight: 500;
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 40px;
  margin-left: -10px;
}
</style>
