<template>
  <main class="container padding">
    <div class="header">
      <label class="title">Protocolo de transferência entre arsenais</label>
      <img src="/logo.png" alt="" class="modal-logo" width="150px"/>
    </div>
    <div class="w">
      <div v-if="!relatorioData">Ocorreu um problema ao gerar o protocolo de transferência entre arsenais</div>
      <b-row>
        <b-col cols="4">
          <label>N° Protocolo:</label>
          <br />
          <div class="info">
            {{ relatorioData.siclaProtocolo || "-" }}
          </div>
          <br />
        </b-col>

        <b-col cols="4">
          <label>Data/Hora</label>
          <br />
          <div class="info">
            {{ this.getDateTime(relatorioData.dateTime) }}
          </div>
          <br />
        </b-col>

        <b-col cols="4">
          <label>Unidade:</label>
          <br />
          <div class="info">
            {{ relatorioData.unidade && relatorioData.unidade.nome }}
          </div>
          <br />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <label>Responsável CME:</label>
          <br />
          <div class="info">
            {{ relatorioData.usuario && relatorioData.usuario.nome }}
          </div>
          <br />
        </b-col>

        <b-col cols="4">
          <label>Unidade Destino:</label>
          <br />
          <div class="info">
            {{ relatorioData.unidadeDestino }}
          </div>
          <br />
        </b-col>

        <b-col cols="4">
          <label>Setor Destino:</label>
          <br />
          <div class="info">
            CME
          </div>
          <br />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="div-assinaturas">
            <span>Colaborador CME: </span>
            <div class="underline"></div>
          </div>
          <br />
        </b-col>
      </b-row>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" style="font-size: 14px">#</th>
            <th scope="col" style="font-size: 14px">Tipo de artigo</th>
            <th scope="col" style="font-size: 14px">ID</th>
            <th scope="col" style="font-size: 14px">Descrição</th>
            <th scope="col" style="font-size: 14px">Qtd peças cadastradas</th>
            <th scope="col" style="font-size: 14px">Setor</th>
            <th scope="col" style="font-size: 14px">Unidade</th>
            <th v-if="hasMaterialGenerico" scope="col" style="font-size: 14px">Qtd</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in relatorioData.materiais" v-bind:key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ item.tipoMaterial || item.tipoMaterialGenerico || '-' }}</td>
            <td>{{ item.shownId || '-' }}</td>
            <td>{{ item.descricao }}</td>
            <td>{{ item.quantidade_itens || item.pecas || '-' }}</td>
            <td>{{ item.setor }}</td>
            <td>{{ item.unidadePatrimonio }}</td>
            <td v-if="hasMaterialGenerico">{{ item.qt_material_generico || '-' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script>
import moment from 'moment';

export default {
  name: 'RelatorioRetornoRecebimento',
  props: {
    relatorioData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getDateTime(dateTime) {
      return moment(dateTime).local().format('DD/MM/YYYY HH:mm:ss');
    },
  },
  computed: {
    hasMaterialGenerico() {
      return this.relatorioData.materiais.some((mat) => mat.qt_material_generico);
    },
  },
};
</script>

<style scoped>
* {
  font-weight: 400;
  font-size: 16px;
}

.padding {
  padding: 36px;
  font-weight: 400;
}

label {
  margin-bottom: 3px;
}

.info {
  margin-bottom: 12px;
}

.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}

.div-assinaturas {
  display: flex;
  margin-bottom: 10px;
}

.modal-header {
  border-bottom: 0;
}

.header .modal-logo {
  position: absolute;
  right: 20px;
  top: 20px;
  margin-top: 10px;
}

.header .title {
  font-weight: 500;
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 40px;
  margin-left: -10px;
}
</style>
