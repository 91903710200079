<template>
<div>
  <div class="etiqueta pt-3">
    <b-row>
      <b-col sm="5" md="5" lg="5" xl="5">
        <div>
          <img class="pb-3" src="/logo.png" alt="" width="150px"/>
        </div>
        <div id="qrCode" class="text-center pb-4">
          <qrcode-vue
            :value="etiquetaData.code"
            level="H"
            class="qr-code"
            renderAs="svg"
          ></qrcode-vue>
        </div>
      </b-col>
      <b-col class="pt-1" sm="7" md="7" lg="7" xl="7">
        <div v-if="etiquetaData">
          Descrição do material:
          <b>{{ etiquetaData.descricao }}
          </b>
          <br />
          <span v-if="etiquetaData.shownId">
            ID:
            <b>{{ etiquetaData.shownId }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.tipoProcessamento">
            Processamento:
            <b>{{ etiquetaData.tipoProcessamento }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.lote">
            Lote:
            <b>{{ etiquetaData.lote }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.quantidade_itens">
            Quantidade de peças:
            <b>{{ etiquetaData.quantidade_itens }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.justificativa && etiquetaData.observacoes">
            Just / Obs :
            <b>{{ `${etiquetaData.justificativa} / ${etiquetaData.observacoes}` }}</b>
            <br />
          </span>
          <span v-else-if="etiquetaData.justificativa">
            Justificativa:
            <b>{{ etiquetaData.justificativa }}</b>
            <br />
          </span>
          <span v-else-if="etiquetaData.observacoes">
            Observações:
            <b>{{ etiquetaData.observacoes }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.medico">
            Médico:
            <b>{{ etiquetaData.medico }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.fornecedor">
            Fornecedor:
            <b>{{ etiquetaData.fornecedor }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.validade">
            Validade:
            <b>{{ etiquetaData.validade }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.embalagem">
            Embalagem:
            <b>{{ etiquetaData.embalagem }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.protocoloRecebimento">
            Protocolo Recebimento:
            <b>{{ etiquetaData.protocoloRecebimento }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.setor && etiquetaData.unidade">
            Pertence a:
            <b>{{ `${etiquetaData.unidade} - ${etiquetaData.setor}` }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.setorOrigem && etiquetaData.unidadeOrigem">
            Entregue por:
            <b>{{ `${etiquetaData.unidadeOrigem} - ${etiquetaData.setorOrigem}` }}</b>
            <br />
          </span>
          <div>
            Responsável pelo preparo:
            <b>{{ etiquetaData.usuario }}</b>
          </div>
          <div v-if="etiquetaData.responsavel_dupla_checagem">
            Responsável dupla checagem:
            <b>{{ etiquetaData.responsavel_dupla_checagem }}</b>
          </div>
          <br />
          Data/Hora:
          {{ getDateTime(relatorioData.dateTime) }}
          <br />
        </div>
        <div v-else>
          Ocorreu um problema ao gerar a etiqueta
        </div>
      </b-col>
    </b-row>
    <b-row id="box-bloco-circulante">
      <div id="div-title-bloco-circulante"><h5 id="title-bloco-circulante">BLOCO CIRÚRGICO (CIRCULANTE)</h5></div>
      <div class="l-BC" id="l1-BC"><span id="label-l1-BC">Nome: </span><div class="underline"></div></div>
      <div class="l-BC" id="l2-BC">
        <div class="sub-item-l"><span id="l2-t1-BC">Sala: </span><div class="underline"></div></div>
        <div class="sub-item-l">
          <span id="l2-t2-BC">Data: </span>
          <div class="underline"></div>
        </div>
      </div>
      <div class="l-BC" id="l3-BC"><span>Número de peças: </span><div class="underline"></div></div>
      <div class="l-BC" id="l4-BC"><span>Obs: </span><div class="underline"></div></div>
    </b-row>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';

export default {
  props: {
    relatorioData: {
      type: Object,
      default() {
        return {};
      },
    },
    indexMaterial: {
      type: Number,
      default: 0,
    },
  },

  components: {
    QrcodeVue,
  },

  computed: {
    etiquetaData() {
      return this.relatorioData.materiais[this.indexMaterial];
    },
  },

  methods: {
    getDateTime(dateTime) {
      return moment(dateTime).local().format('DD/MM/YY HH:mm');
    },
  },
};
</script>

<style scoped>
.etiqueta {
  border-top: 10px solid #209f85;
  margin-left: 1cm !important;
}
#box-bloco-circulante {
  /* border: solid 1px black; */
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
}
#div-title-bloco-circulante {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
}
#title-bloco-circulante {
  text-decoration: underline;
}
.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}
.sub-item-l {
  display: flex;
  width: 50%;
}
.l-BC {
  display: flex;
  margin-bottom: 10px;
}
</style>
