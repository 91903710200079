<template>
  <div>
    <b-row class="input-p">
      <b-col sm="12" md="6" lg="6" xl="6" v-if="hasQuantidadeItens">
        <b-form-group label="Quantidade de peças*" label-for="quantidade_pecas">
          <b-form-input
            id="quantidade_pecas"
            type="number"
            :value="dataModel.quantidade_pecas"
            autocomplete="off"
            class="invision-input"
            name="quantidade_pecas"
            :disabled="isBusy"
            min="1"
            v-validate={required:!saveDataInModel,betweenFiftyPorcentLessAndGreater:[dataModel.quantidade_itens]}
            :state="validateState('quantidade_pecas')"
            @input="v => handleChangeField('quantidade_pecas', v)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="!saveDataInModel">
      <hr />
      <br />
      <b-row>
        <b-col cols="12">
          <b-form-group label="Observações" label-for="observacoes">
            <b-form-input
              name="observacoes"
              :value="dataModel.observacoes"
              autocomplete="off"
              class="invision-input"
              v-validate={maxLengthOfJustAndObsConcat:[dataModel.justificativa]}
              :state="validateState('observacoes')"
              :disabled="isBusy"
              @input="v => handleChangeField('observacoes', v)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InformacoesManutencao',
  inject: ['parentValidator', 'validateState', 'updateCurrentMaterialModel', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },

  props: {
    currentMaterialModel: {
      type: Object,
    },
    model: {
      type: Object,
    },
    isBusy: {
      type: Boolean,
    },
    forceHasQtdItens: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    saveDataInModel() {
      if (this.model) return true;
      return false;
    },
    hasQuantidadeItens() {
      if (this.forceHasQtdItens) return true;
      if (this.dataModel.id_material_generico) return true;
      if (this.saveDataInModel) {
        if (!this.model.materiais || !this.model.materiais.length) return true;
        return Boolean(this.model.materiais[0].quantidade_itens);
      }
      return this.dataModel.camposMaterial
        && this.dataModel.camposMaterial.quantidade_itens
        && this.dataModel.camposMaterial.quantidade_itens.necessario;
    },
    dataModel() {
      if (this.saveDataInModel) {
        return this.model;
      }
      return this.currentMaterialModel;
    },
    justError() {
      if (this.veeErrors.first('justificativa') && this.veeErrors.first('justificativa').match('is required')) {
        return 'Campo obrigatório';
      }
      return `A quantidade de caracteres da justificativa superou o limite.`;
    },
  },
  methods: {
    handleChangeField(field, value) {
      if (this.saveDataInModel) {
        this.updateModel(field, value);
      } else {
        this.updateCurrentMaterialModel(field, value);
      }
    },
  },
  // watch: {
  //   dataModel: {
  //     deep: false,
  //     handler(dataModel) {
  //       if(!this.hasJustificativa && dataModel.justificativa) {
  //         this.handleChangeField('justificativa', '')
  //       }
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.btn.secondary-contained-button {
  margin-left: 12px;
}

form .input-p {
  padding-bottom: 24px; /* define o espaçamento entre os inputs */
}

form .input-p .form-group {
  margin-bottom: 0; /* tira margin de 16 px já existente no gupo correspondente ao primeiro input do formulario */
}
</style>
