<template>
  <div class="component-wrapper" :style="css">
    <b-row v-if="!hideHeader" class="header-wrapper">
      <b-col sm="6">
        <span>Checagem de Peças</span>
      </b-col>
      <b-col sm="6" class="d-flex justify-content-end">
        <ChevronDownIcon />
      </b-col>
    </b-row>
    <b-container fluid>
      <b-row>
        <b-col class="table-wrapper">
          <b-row>
            <b-col>
              <b-row class="table-header">
                <b-col sm="4">
                  <span>Peça</span>
                </b-col>
                <b-col sm="3">
                  <span>Quantidade</span>
                </b-col>
                <b-col sm="5" class="d-flex justify-content-end">
                  <span>Check</span>
                </b-col>
              </b-row>
              <b-row class="table-body">
                <b-col>
                  <b-row
                    class="table-entry"
                    v-for="(peca, index) in currentMaterialModel.verified_items"
                    :key="peca.id_tipo_peca_material"
                  >
                    <b-col sm="4" :id="peca.id_tipo_peca_material">
                      <b-tooltip
                        v-if="peca.justificativa"
                        :target="String(peca.id_tipo_peca_material)"
                        triggers="hover"
                      >
                        <b>Justificativa:</b> {{ peca.justificativa }}
                      </b-tooltip>
                      <span
                        :class="peca.justificativa && 'justificativa-text'"
                        class="item-row-text"
                      >
                        {{ peca.nome }}
                      </span>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                        :key="peca.id_tipo_peca_material"
                        :id="`input-${peca.id_tipo_peca_material}`"
                        :name="`input-${peca.id_tipo_peca_material}`"
                        :ref="`input-${peca.id_tipo_peca_material}`"
                        type="number"
                        min="0"
                        :value="
                          currentMaterialModel.verified_items[index]['quantidade_atual']
                        "
                        @input="
                          (v) => {
                            handleInputChange({
                              item: peca,
                              field: 'quantidade_atual',
                              value: v
                            });
                          }
                        "
                      />
                    </b-col>
                    <b-col sm="5" class="d-flex justify-content-end">
                      <b-form-checkbox v-model="peca.status" v-validate="'required'"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ItemCheck",
  inject: ["parentValidator", "updateCurrentMaterialModel"],
  data() {
    return {
      SEARCH_CRITERIA: "id_tipo_peca_material",

      itemsSource: "",
      sourceOfTruth: [],
    };
  },
  created() {
    this.$validator = this.parentValidator;
    const restore = this.currentMaterialModel.verified_items;

    if (restore) {
      return this.restoreVerifiedItems();
    }
    this.setVerifiedItems();
    this.setTotalItemsQuantity({ reference: "pecas" });
  },
  props: {
    validateState: {
      type: Function,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    marginTop: {
      type: String,
      required: false,
      default: "10",
    },
    marginBottom: {
      type: String,
      required: false,
      default: "10",
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    removeObservation() {
      this.updateCurrentMaterialModel("justificativa", "");
    },
    dereference({ source, key }) {
      return source[key].map((item) => ({ ...item }));
    },
    setTotalItemsQuantity({ reference }) {
      const sum = (acc, curr) => {
        return +acc + +curr.quantidade_atual;
      }
      const op = this.currentMaterialModel[reference].reduce(sum, 0);
      this.updateCurrentMaterialModel("quantidade_pecas", op);
    },
    restoreVerifiedItems() {
      this.currentMaterialModel.verified_items = this.dereference({
        source: this.currentMaterialModel,
        key: "verified_items",
      });
    },
    setVerifiedItems() {
      this.currentMaterialModel.verified_items = this.dereference({
        source: this.currentMaterialModel,
        key: "pecas",
      });
    },
    findIndexInVerifiedItem({ target, criteria }) {
      return this.currentMaterialModel.verified_items.findIndex((_item) =>
        this.searchByCriteria({ target, item: _item, criteria })
      );
    },

    searchByCriteria({ target, item, criteria }) {
      return target[criteria] === item[criteria];
    },

    changeItemStatus({ item, status }) {
      this.updateItem({item, field: 'status', value: status})
    },
    updateItem({item, field, value}) {
      item[field] = value
    },
    handleInputChange({item, field, value }) {
      const quantitiesAreEqual = this.currentMaterialModel.quantidade_atual_itens === this.currentMaterialModel.quantidade_pecas

      this.updateItem({item, field, value})
      this.changeItemStatus({ item, status: false });
      this.setTotalItemsQuantity({ reference: "verified_items" });
      if(quantitiesAreEqual) this.removeObservation()
    },

    getAllItemsChecked() {
      const allChecked = this.currentMaterialModel.verified_items.every(
        (item) => item.status
      );
      return allChecked;
    },
  },

  computed: {
    css() {
      return {
        "--margin-top": this.marginTop + "px",
        "--margin-bottom": this.marginBottom + "px",
      };
    },
  },
  watch: {
    allItemsChecked(allChecked) {
      this.$emit("all-items-checked", allChecked);
      if (allChecked) {
        return this.$emit("verified-items", {
          verifiedItems: this.currentMaterialModel.verified_items,
        });
      }
      this.$emit("verified-items", { verifiedItems: {}, totalQuantity: null });
    },
  },
};
</script>

<style scoped>
.item-row-text {
  cursor: pointer;
}
.justificativa-text:first-child::before {
  position: absolute;
  left: 0;
  top: 7px;
  content: " ";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #da4949;
  opacity: 0.8;
}
.justificativa-text {
  color: #da4949;
}
.justificativa-button,
.justificativa-input {
  margin: 5px 0;
}
.justificativa-wrapper {
  margin: 20px 0;
}
.header-wrapper {
  margin-bottom: 20px;
}
.table-wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.table-header {
  border-bottom: 1px solid #ddd;
  padding: 15px 10px;
}
.table-entry {
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-entry:nth-child(odd) {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.table-entry:first-child {
  border-top: none;
}
.table-entry:last-child {
  border-bottom: none;
}
.component-wrapper {
  margin-top: var(--margin-top);
  margin-bottom: var(--margin-bottom);
}
</style>