<template>
  <div id="wrapper">
    <b-row class="mb-2">
      <b-col>
        <p>Dupla Checagem</p>
      </b-col>
    </b-row>
    <div id="controls-wrapper">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Usuario*"
            label-for="usuario_checagem"
            class="input-text"
          >
            <b-form-input
              id="usuario_checagem"
              name="usuario_checagem"
              ref="usuario_checagem"
              v-model="currentMaterialModel.usuario_dupla_checagem"
              :disabled="!!currentMaterialModel.usuarioDuplaChecagem"
              v-validate="{
                required: !Boolean(currentMaterialModel.usuarioDuplaChecagem),
              }"
              :state="validateState('usuario_checagem')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Senha*"
            label-for="senha_checagem"
            class="input-text"
          >
            <b-form-input
              id="senha_checagem"
              name="senha_checagem"
              ref="senha_checagem"
              type="password"
              v-model="currentMaterialModel.senha_dupla_checagem"
              :disabled="!!currentMaterialModel.usuarioDuplaChecagem"
              v-validate="{
                required: !Boolean(currentMaterialModel.usuarioDuplaChecagem),
              }"
              :state="validateState('senha_checagem')"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "DuplaChecagem",
  inject: ["validateState", "parentValidator"],
  props: {
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>

<style scoped>
#controls-wrapper {
  border-top: 1px solid #d8d8da;
  background: #f7f7fa;
  padding: 25px;
}
.input-text {
  color: #40434e;
}
</style>